import { Language } from "../type"

export const ja: Language = {
  Auth: {
    SignIn: "ログイン",
    Logout: "ログアウト",
    Register: "はじめる",
  },
  Model: {
    App: "アプリ",
    AppName: "アプリ名",
    Coin: "コイン",
    Wallet: "ウォレット",
    WalletName: "ウォレット名",
    Network: "ネットワーク",
    AddressPayIn: "払い出しアドレス",
    AddressPayOut: "支払いアドレス",
  },
  Menu: {
    Mypage: "マイページ",
    Application: "アプリケーション",
    Wallet: "ウォレット",
    Plan: "プラン",
    Setting: "設定",
  },
  Math: {
    Kilo: "千",
  },
  Finance: {
    Gas: "手数料",
    Income: "収入",
    Expenses: "支出",
    Net: "収支",
    CumulativeNet: "合計収支",
    CumulativeIncome: "合計収入",
    CumulativeExpenses: "合計支出",
    MonthlyNet: "今月収支",
    MonthlyIncome: "今月収入",
    MonthlyExpenses: "今月支出",
    BreakdownIncome: "収入割合",
    BreakdownExpenses: "支出割合",
  },
  Actions: {
    Edit: "編集",
    Create: "作成",
    Update: "更新",
    Delete: "削除",
    Cancel: "キャンセル",
  },
  Wallet: {
    Address: "アドレス",
    PayOut: "支出",
    PayIn: "入金",
    Label: "ラベル",
  },
  Setting: {
    Language: "言語",
    Fiat: "通貨",
    IncomeCalculation: "収入計算",
    ExpensesCalculation: "支出計算",
  },
  Fiat: {
    USD: "ドル",
    JPY: "日本円",
  },
  CalculateType: {
    Min: "最小価格",
    Max: "最大価格",
    Avg: "平均価格",
  },
  Component: {
    TotalNet: "アプリ別収支",
  },
  CalculateResult: {
    Normal: "正常",
    Defect: "欠損あり",
  },
  Plan: {
    Plan: "プラン",
    Detail: "もっと長い期間の統計を取得して、優位に立ち回ろう！",
    Current: "現在の",
    Basic: "平民",
    Load: "貴族",
    King: "王様",
    OneMonth: "１ヶ月の収支情報",
    ThreeMonth: "３ヶ月の収支情報",
    Infinity: "無期限の収支情報",
    UpdateAll: "一括情報更新",
    Becoming: "になる",
    BecomingOrder: "Before",
  },
  Date: {
    Month: "月",
  },
  Property: {
    Date: "日付",
    Status: "ステータス",
    Hash: "ハッシュ",
    Value: "合計",
  },
}

import styled from "@emotion/styled"
import formatInTimeZone from "date-fns-tz/formatInTimeZone"
import { FC } from "react"
import { CoinModel, TransactionModel } from "../../../../types/model"
import { useI18N } from "../../../hooks"
import { OptionalText } from "../../atoms"
import { WideTable } from "../../molecules"

export type TransactionTableProps = {
  transactions: TransactionModel[]
  coins: CoinModel[]
}

export const TransactionTable: FC<TransactionTableProps> = ({
  transactions,
  coins,
}) => {
  const { t } = useI18N()
  return (
    <WideTable
      columns={[
        { Header: t("Property", "Date"), accessor: "date" },
        {
          Header: t("Property", "Hash"),
          accessor: "hash",
          headerAlign: "center",
          align: "center",
          Wrapper: ({ value, children }) => (
            <HashLink
              href={`https://bscscan.com/tx/${value}`}
              target="_blank"
              rel="noreferrer"
            >
              <OptionalText>{children}</OptionalText>
            </HashLink>
          ),
          onClick: () => {},
        },
        {
          Header: t("Property", "Status"),
          accessor: "direction",
          headerAlign: "center",
          align: "center",
        },
        {
          Header: t("Finance", "Gas"),
          accessor: "gas",
          headerAlign: "center",
          align: "right",
        },
        {
          Header: t("Model", "Coin"),
          accessor: "coin",
          headerAlign: "center",
          align: "center",
        },
        {
          Header: t("Property", "Value"),
          accessor: "value",
          headerAlign: "center",
          align: "right",
        },
      ]}
      data={transactions?.map(
        ({ hash, direction, coinId, gas, value, time }) => ({
          date: formatInTimeZone(time.toDate(), "UTC", "MM/dd"),
          hash,
          direction,
          gas: gas,
          coin: coins.find(({ id }) => coinId === id)?.name,
          value: Number(value),
        })
      )}
    />
  )
}

const HashLink = styled.a`
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`

import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import * as Languages from "./languages"

const resources = Object.entries(Languages).reduce(
  (res, [key, value]) => ({ ...res, [key]: { translation: value } }),
  {}
)

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n

import { FC } from "react"
import { Button } from "react-actors/buttons"
import { useNavigate } from "react-router-dom"
import { WalletModel } from "../../../../types/model"
import { useI18N } from "../../../hooks"
import { Signs } from "../../../routes/signs"
import { Content, Media, WideTable } from "../../molecules"

export type WalletListTemplateProps = {
  data: WalletModel[]
  onDelete?: (address: string) => Promise<unknown>
}

export const WalletListTemplate: FC<WalletListTemplateProps> = ({ data }) => {
  const navigate = useNavigate()
  const { t } = useI18N()
  return (
    <Content
      title={t("Menu", "Wallet")}
      side={
        <Button color="normal" onClick={() => navigate(Signs.WalletCreate)}>
          {t("Actions", "Create")}
        </Button>
      }
    >
      <Media>
        <WideTable
          columns={[
            { Header: t("Wallet", "Label"), accessor: "label" },
            { Header: t("Wallet", "Address"), accessor: "address" },
          ]}
          data={data.map(({ address, label }) => ({
            label,
            address,
          }))}
          onClick={({ address }) => navigate(`/wallets/${address}`)}
        />
      </Media>
    </Content>
  )
}

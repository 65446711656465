import styled from "@emotion/styled"
import { FC } from "react"
import { Button } from "react-actors/buttons"
import {
  AppModel,
  CoinModel,
  NetworkModel,
  WalletModel,
} from "../../../../types/model"
import { useI18N } from "../../../hooks"
import { AppFormTable } from "../../organisms"

export type AppEditTemplateProps = {
  app?: AppModel
  networks?: NetworkModel[]
  coins?: CoinModel[]
  wallets?: WalletModel[]
  onEdit: (value: AppModel) => Promise<void>
  onDelete: () => Promise<void>
}

export const AppEditTemplate: FC<AppEditTemplateProps> = ({
  app,
  networks,
  coins,
  wallets,
  onEdit,
  onDelete,
}) => {
  const { t } = useI18N()
  return (
    <>
      <SideContent>
        <Button color="normal" onClick={onDelete}>
          {t("Actions", "Delete")}
        </Button>
      </SideContent>
      <AppFormTable
        app={app}
        networks={networks}
        coins={coins}
        wallets={wallets}
        onEdit={onEdit}
      />
    </>
  )
}

const SideContent = styled.div`
  display: flex;
  justify-content: flex-end;
`

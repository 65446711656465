import styled from "@emotion/styled"
import { FC } from "react"
import { CumulativeGainModel, GainModel } from "../../../../types/model"
import { Media } from "../../molecules"
import { CumulativeGraph } from "../CumulativeGraph"
import { CumulativeStatus } from "../CumulativeStatus"

export type CumulativeGainSummaryProps = {
  cumulatives: CumulativeGainModel[]
  gains: GainModel[]
  last: CumulativeGainModel
  range: number
  before?: CumulativeGainModel
}

export const CumulativeGainSummary: FC<CumulativeGainSummaryProps> = ({
  cumulatives,
  gains,
  last,
  range,
  before,
}) => (
  <Box>
    <GraphBox>
      <CumulativeGraph
        cumulatives={cumulatives}
        gains={gains}
        before={before}
        range={range}
      />
    </GraphBox>
    <StatusBox>
      <Status cumulative={last} gains={gains} />
    </StatusBox>
  </Box>
)

const Box = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-direction: row;
  margin-bottom: -1rem;
  @media (max-width: 800px) {
    flex-direction: column;
    margin-bottom: 0;
  }
`

const GraphBox = styled(Media)`
  width: 100%;
  padding-top: 1.5rem;
  margin-bottom: 1rem;
  @media (max-width: 800px) {
    margin-bottom: 0;
  }
`

const StatusBox = styled.div`
  padding-bottom: 1rem;
`

const Status = styled(CumulativeStatus)`
  height: 100%;
`

import { keyframes } from "@emotion/react"
import styled from "@emotion/styled"
import { IconRefresh } from "@tabler/icons"
import { FC, UIEvent, useState } from "react"
import { useMode } from "react-actors/hooks"

export type RefreshButtonProps = {
  className?: string
  disabled?: boolean
  onClick: (e: UIEvent) => Promise<unknown> | unknown
}

export const RefreshButton: FC<RefreshButtonProps> = ({
  className,
  disabled,
  onClick,
}) => {
  const mode = useMode()
  const [sending, setSending] = useState(false)
  return (
    <Box className={className}>
      <Refresh
        className={sending ? "sending" : ""}
        size=".8rem"
        color={
          disabled
            ? mode === "dark"
              ? "var(--gray-500)"
              : "var(--gray-300)"
            : undefined
        }
        onClick={
          !disabled
            ? async (e) => {
                setSending(true)
                await onClick(e)
                setSending(false)
              }
            : (e) => {
                e.preventDefault()
                e.stopPropagation()
              }
        }
      />
    </Box>
  )
}

const rotate = keyframes`
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0);
  }
`

const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1rem 0.75rem 0.5rem;
`

const Refresh = styled(IconRefresh)`
  &.sending {
    animation: ${rotate} 1s ease-in-out infinite;
  }
`

import { createCheckoutSession } from "@stripe/firestore-stripe-payments"
import { FC } from "react"
import { useMultiFetcher, useProducts } from "../../hooks"
import { useStripePortal } from "../../hooks/useStripePortal"
import { useSubscription } from "../../hooks/useSubscription"
import { payments } from "../../services/stripe"
import { LoadingTemplate, PlanTemplate } from "../templates"

export const PlanPage: FC = () => {
  const { isLoading, isIdle, data } = useMultiFetcher({
    products: useProducts(),
    subscription: useSubscription(),
    portal: useStripePortal(window.location.href),
  })
  const onPortal = (): Promise<void> => {
    window.location.assign(data.portal.url)
    return new Promise((resolve) => setTimeout(() => resolve(), 5000))
  }
  return !isLoading && !isIdle ? (
    <PlanTemplate
      subscription={data.subscription[0]}
      plans={data.products}
      onPortal={onPortal}
      onSubscribe={async (priceId) => {
        if (data.subscription.length) {
          return onPortal()
        }
        const session = await createCheckoutSession(payments, {
          price: priceId,
          allow_promotion_codes: true,
        })
        window.location.assign(session.url)
      }}
    />
  ) : (
    <LoadingTemplate />
  )
}

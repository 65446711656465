import formatInTimeZone from "date-fns-tz/formatInTimeZone"
import { FC } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { CoinModel, PriceModel } from "../../../../types/model"
import { useFiatFormat, useI18N, useUser } from "../../../hooks"
import { firebaseAuth } from "../../../services"
import { WideTable } from "../../molecules"

export type PriceTableProps = {
  coinId: string
  coins: CoinModel[]
  prices: PriceModel[]
}

export const PriceTable: FC<PriceTableProps> = ({ coinId, prices, coins }) => {
  const [user] = useAuthState(firebaseAuth)
  const { data } = useUser(user)
  const { format: fiatFormat } = useFiatFormat()
  const { t } = useI18N()
  const coin = coins.find((coin) => coin.id === coinId)
  return (
    <WideTable
      columns={[
        { Header: t("Property", "Date"), accessor: "date" },
        { Header: t("Model", "Coin"), accessor: "coin" },
        { Header: t("CalculateType", "Max"), accessor: "max" },
        { Header: t("CalculateType", "Min"), accessor: "min" },
        { Header: t("CalculateType", "Avg"), accessor: "avg" },
      ]}
      data={prices.map(({ date, jpy, usd }) => {
        const price = data?.fiat === "jpy" ? jpy : usd
        return {
          date: formatInTimeZone(date.toDate(), "UTC", "M/d"),
          coin: coin?.name,
          max: price && fiatFormat(price.max, 2),
          min: price && fiatFormat(price.min, 2),
          avg: price && fiatFormat((price.max + price.min) / 2, 2),
        }
      })}
    />
  )
}

import { useAuthState } from "react-firebase-hooks/auth"
import { useTranslation } from "react-i18next"
import { firebaseAuth } from "../services"
import { useUser } from "./useUser"

type Return = {
  format: (value: number, digits?: number) => string | undefined
}

export const useFiatFormat = (): Return => {
  const [user] = useAuthState(firebaseAuth)
  const { data } = useUser(user)
  const { i18n } = useTranslation()
  return {
    format: (value, digits = 0) => {
      if (!data) return

      if (data.fiat === "jpy" && i18n.resolvedLanguage === "ja")
        return `${Number(value.toFixed(digits)).toLocaleString(undefined, {
          minimumFractionDigits: digits,
        })}円`
      else if (data.fiat === "jpy")
        return `¥${Number(value.toFixed(digits)).toLocaleString(undefined, {
          minimumFractionDigits: digits,
        })}`
      else if (data.fiat === "usd")
        return `$${Number(value.toFixed(digits + 2)).toLocaleString(undefined, {
          minimumFractionDigits: digits + 2,
        })}`
    },
  }
}

import { httpsCallable } from "firebase/functions"
import { useQuery } from "react-query"
import { firebaseFunctions } from "../services"

export const useStripePortal = (returnUrl: string) => {
  return useQuery("Portal", () =>
    httpsCallable<{ returnUrl: string }, { url: string }>(
      firebaseFunctions,
      "ext-firestore-stripe-payments-createPortalLink"
    )({ returnUrl }).then(({ data }) => data)
  )
}

import { FC } from "react"
import { ModeType } from "react-actors"
import { Image } from "react-actors/images"
import { Link } from "react-router-dom"
import { Signs } from "../../../routes/signs"

export type MainTitleProps = {
  to: typeof Signs[keyof typeof Signs]
  mode: ModeType
  className?: string
}

export const MainTitle: FC<MainTitleProps> = ({ to, mode, className }) => (
  <h1 className={className}>
    <Link to={to}>
      <Image
        src={mode === "light" ? "/logo.png" : "/logo-white.png"}
        width={221}
        height={48}
      />
    </Link>
  </h1>
)

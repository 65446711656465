import { UseQueryResult } from "react-query/types/react/types"

type ExtractModel<T> = T extends UseQueryResult<infer U> ? U : never
type ExtractError<T, X = unknown> = T extends UseQueryResult<X, infer U>
  ? U
  : never

type Model<F extends { [key: string]: M }, M extends UseQueryResult> = {
  [key in keyof F]: ExtractModel<F[key]>
}
type IError<F extends { [key: string]: M }, M extends UseQueryResult> = {
  [key in keyof F]: ExtractError<F[key]>
}
type ResultRec<
  K extends keyof UseQueryResult,
  F extends { [key: string]: M },
  M extends UseQueryResult
> = { [key in keyof F]: UseQueryResult[K] }
export const useMultiFetcher = <
  F extends { [key: string]: M },
  M extends UseQueryResult
>(
  fetchers: F
) =>
  Object.entries(fetchers).reduce(
    (pre, [key, query]) => ({
      data: {
        ...pre.data,
        [key]: query.data,
      },
      error: {
        ...pre.error,
        [key]: query.error,
      },
      refetch: {
        ...pre.refetch,
        [key]: query.refetch,
      },
      remove: {
        ...pre.remove,
        [key]: query.remove,
      },
      status: {
        ...pre.status,
        [key]: query.status,
      },
      isError: pre.isError || query.isError,
      isFetched: pre.isFetched && query.isFetched,
      isFetchedAfterMount: pre.isFetchedAfterMount && query.isFetchedAfterMount,
      isFetching: pre.isFetching || query.isFetching,
      isIdle: pre.isIdle || query.isIdle,
      isLoading: pre.isLoading || query.isLoading,
      isLoadingError: pre.isLoadingError || query.isLoadingError,
      isPlaceholderData: pre.isPlaceholderData || query.isPlaceholderData,
      isPreviousData: pre.isPreviousData || query.isPreviousData,
      isRefetchError: pre.isRefetchError || query.isRefetchError,
      isRefetching: pre.isRefetching || query.isRefetching,
      isStale: pre.isStale || query.isStale,
      isSuccess: pre.isSuccess && query.isSuccess,
    }),
    {
      data: {} as Model<F, M>,
      error: {} as IError<F, M>,
      isError: false,
      isFetched: false,
      isFetchedAfterMount: false,
      isFetching: false,
      isIdle: false,
      isLoading: false,
      isLoadingError: false,
      isPlaceholderData: false,
      isPreviousData: false,
      isRefetchError: false,
      isRefetching: false,
      isStale: false,
      isSuccess: false,
      refetch: {} as ResultRec<"refetch", F, M>,
      remove: {} as ResultRec<"remove", F, M>,
      status: {} as ResultRec<"status", F, M>,
    }
  )

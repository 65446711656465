import styled from "@emotion/styled"
import { User } from "@firebase/auth"
import { FC } from "react"
import { Button } from "react-actors/buttons"
import { Select } from "react-actors/inputs"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { UserForm, UserModel } from "../../../../types/model"
import { useI18N } from "../../../hooks"
import { transForm } from "../../../utils/transformers/transForm"

export type SettingTemplateProps = {
  user: User
  model?: UserModel
  onCreate?: (user: UserForm) => Promise<unknown>
  onUpdate?: (user: UserForm) => Promise<unknown>
}

const languageToFiat = (lang: string): "jpy" | "usd" =>
  lang === "ja" ? "jpy" : "usd"
const initialValue = (user: User | undefined, lang: string): UserForm => ({
  name: user?.displayName ?? null,
  fiat: languageToFiat(lang),
  income: "min",
  expenses: "max",
})

export const SettingTemplate: FC<SettingTemplateProps> = ({
  user,
  model,
  onCreate,
  onUpdate,
}) => {
  const {
    i18n: { resolvedLanguage, changeLanguage },
  } = useTranslation()
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<UserForm>({
    defaultValues: model
      ? transForm({ ...model, name: user.displayName })
      : initialValue(user, resolvedLanguage),
  })
  const { t } = useI18N()
  return (
    <form
      onSubmit={handleSubmit(
        onCreate ? onCreate : onUpdate ? onUpdate : console.log
      )}
    >
      <Table>
        <tbody>
          <tr>
            <Th>{t("Setting", "Language")}</Th>
            <Td>
              <Select
                defaultValue={resolvedLanguage}
                onChange={(e) => changeLanguage(e.target.value)}
              >
                <option value="ja">日本語</option>
                <option value="en">English</option>
              </Select>
            </Td>
          </tr>
          <tr>
            <Th>{t("Setting", "Fiat")}</Th>
            <Td>
              <Select {...register("fiat", { required: true })}>
                <option value="usd">{t("Fiat", "USD")}</option>
                <option value="jpy">{t("Fiat", "JPY")}</option>
              </Select>
            </Td>
          </tr>
          <tr>
            <Th>{t("Setting", "IncomeCalculation")}</Th>
            <Td>
              <Select {...register("income", { required: true })}>
                <option value="min">{t("CalculateType", "Min")}</option>
                <option value="max">{t("CalculateType", "Max")}</option>
                <option value="avg">{t("CalculateType", "Avg")}</option>
              </Select>
            </Td>
          </tr>
          <tr>
            <Th>{t("Setting", "ExpensesCalculation")}</Th>
            <Td>
              <Select {...register("expenses", { required: true })}>
                <option value="min">{t("CalculateType", "Min")}</option>
                <option value="max">{t("CalculateType", "Max")}</option>
                <option value="avg">{t("CalculateType", "Avg")}</option>
              </Select>
            </Td>
          </tr>
          <tr>
            <th />
            <Action>
              <Button type="submit" color="secondary" loading={isSubmitting}>
                {onCreate ? t("Actions", "Create") : t("Actions", "Edit")}
              </Button>
            </Action>
          </tr>
        </tbody>
      </Table>
    </form>
  )
}

const Table = styled.table`
  width: 100%;
`
const Th = styled.th`
  vertical-align: middle;
  width: 12rem;
`
const Td = styled.td`
  padding: 0 1rem 0.5rem 0;
`
const Action = styled(Td)`
  display: flex;
  justify-content: flex-end;
`

import { FC } from "react"
import { useTheme } from "react-actors/hooks/useTheme"
import { PolarAngleAxis, RadialBar, RadialBarChart } from "recharts"

export type CircleIndicatorProps = {
  value: number
  size?: number
  bar?: number
}

export const CircleIndicator: FC<CircleIndicatorProps> = ({
  size = 170,
  bar = 15,
  value,
}) => {
  const { font } = useTheme("normal")
  return (
    <RadialBarChart
      width={size}
      height={size}
      cx={size / 2}
      cy={size / 2}
      innerRadius={(size / 30) * 12}
      outerRadius={(size / 30) * 18}
      barSize={bar}
      data={[{ value: value === Infinity ? 100 : value }]}
      startAngle={90}
      endAngle={-270}
    >
      <defs>
        <linearGradient id="Line" x1="1" y1="1" x2="0" y2="0">
          <stop stopColor="#FDBC14" />
          <stop offset="1" stopColor="#F88C16" />
        </linearGradient>
      </defs>
      <PolarAngleAxis
        type="number"
        domain={[0, 100]}
        angleAxisId={0}
        tick={false}
      />
      <RadialBar
        background
        dataKey="value"
        cornerRadius={size / 2}
        fill="url(#Line)"
      />
      <text
        x={size / 2}
        y={size / 2 + size / 48}
        textAnchor="middle"
        dominantBaseline="middle"
        fill={font}
        style={{
          fontWeight: "bold",
          fontSize: `${size / 4}px`,
        }}
      >
        {value}%
      </text>
    </RadialBarChart>
  )
}

import { doc, setDoc } from "firebase/firestore"
import { FC } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { useNavigate } from "react-router-dom"
import { Signs } from "../../routes/signs"
import { firebaseAuth, firebaseFirestore } from "../../services"
import { WalletCreateTemplate } from "../templates"

export const WalletCreatePage: FC = () => {
  const push = useNavigate()
  const [user] = useAuthState(firebaseAuth)

  return (
    <WalletCreateTemplate
      onCreate={async (d) => {
        await setDoc(
          doc(firebaseFirestore, "users", user?.uid!, "wallets", d.address),
          d
        )
        return push(Signs.WalletList)
      }}
    />
  )
}

import { httpsCallable } from "firebase/functions"
import { FC } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { TransactionRefreshInput } from "../../../types/model"
import { useApp, useCoins, useMultiFetcher } from "../../hooks"
import { firebaseAuth, firebaseFunctions } from "../../services"
import { AppDetailTemplate, LoadingTemplate } from "../templates"

export const AppDetailPage: FC = () => {
  const navigate = useNavigate()
  const [user] = useAuthState(firebaseAuth)
  const { id } = useParams()
  const { data, isLoading, isError } = useMultiFetcher({
    app: useApp(user, id),
    coins: useCoins(),
  })
  return isLoading || isError ? (
    <LoadingTemplate />
  ) : !data || !id ? (
    <LoadingTemplate />
  ) : (
    <AppDetailTemplate
      id={id}
      coins={data.coins}
      data={data.app}
      onRefresh={() =>
        httpsCallable(
          firebaseFunctions,
          "refreshApp"
        )({
          appId: data?.app.id,
          mode: "initialize",
        } as TransactionRefreshInput).catch((e) => {
          console.error(e)
          toast.error("Refresh Error")
          throw e
        })
      }
      onUpdate={() => navigate(`/apps/${id}/edit`)}
    />
  )
}

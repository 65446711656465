import { User } from "@firebase/auth"
import { subMonths } from "date-fns"
import { orderBy, where } from "firebase/firestore"
import { GainModel } from "../../types/model"
import { useCollection } from "./useCollection"

export const useGains = (
  user: User | undefined | null,
  date: Date = subMonths(new Date(), 1)
) =>
  useCollection<GainModel>(
    ["Gains", date.toDateString()],
    ["users", user?.uid, "gains"],
    [where("date", ">=", date), orderBy("date", "desc")]
  )

import { doc, setDoc } from "firebase/firestore"
import { FC } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { toast } from "react-toastify"
import { UserForm } from "../../../types/model"
import { useUser } from "../../hooks/useUser"
import { firebaseAuth, firebaseFirestore } from "../../services"
import { LoadingTemplate, SettingTemplate } from "../templates"

export const SettingPage: FC = () => {
  const [user] = useAuthState(firebaseAuth)
  const { data, isLoading, isError } = useUser(user)
  return isLoading || isError ? (
    <LoadingTemplate />
  ) : (
    <SettingTemplate
      user={user!}
      model={data!}
      onUpdate={(model: UserForm) =>
        setDoc(doc(firebaseFirestore, "users", user?.uid!), model, {
          merge: true,
        })
          .then(() => toast.success("Updated"))
          .catch((e) => toast.error(`Update failed.\n${e}`))
      }
    />
  )
}

import styled from "@emotion/styled"
import { FC } from "react"
import { CumulativeGainModel, GainModel } from "../../../../types/model"
import { useFiatFormat, useI18N } from "../../../hooks"
import {
  CircleIndicator,
  DataSummary as _DataSummary,
  Media,
} from "../../molecules"

export type CumulativeStatusProps = {
  cumulative?: CumulativeGainModel
  gains: GainModel[]
  className?: string
}

export const CumulativeStatus: FC<CumulativeStatusProps> = ({
  cumulative,
  gains,
  className,
}) => {
  const { t } = useI18N()
  const { format } = useFiatFormat()
  const contents = [
    {
      key: t("Finance", "MonthlyNet"),
      head: t("Finance", "MonthlyNet"),
      content: format(gains.reduce((pre, cur) => pre + cur.net, 0)),
    },
    {
      key: t("Finance", "MonthlyIncome"),
      head: t("Finance", "MonthlyIncome"),
      content: format(gains.reduce((pre, cur) => pre + cur.income, 0)),
    },
    {
      key: t("Finance", "MonthlyExpenses"),
      head: t("Finance", "MonthlyExpenses"),
      content: format(gains.reduce((pre, cur) => pre + cur.expenses, 0)),
    },
    {
      key: t("Finance", "CumulativeNet"),
      head: t("Finance", "CumulativeNet"),
      content: format(cumulative?.net ?? 0),
    },
    {
      key: t("Finance", "CumulativeIncome"),
      head: t("Finance", "CumulativeIncome"),
      content: format(cumulative?.income ?? 0),
    },
    {
      key: t("Finance", "CumulativeExpenses"),
      head: t("Finance", "CumulativeExpenses"),
      content: format(cumulative?.expenses ?? 0),
    },
  ]

  return (
    <Media className={className}>
      <Box>
        <IndicatorBox>
          <CircleIndicator
            value={Math.round(
              cumulative ? (cumulative.income / cumulative.expenses) * 100 : 0
            )}
          />
        </IndicatorBox>
        <DataSummary contents={contents} />
      </Box>
    </Media>
  )
}

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 800px) {
    width: 100%;
    flex-direction: row;
  }
`

const IndicatorBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.75rem;
  @media (max-width: 800px) {
    margin-bottom: 0;
    width: 50%;
  }
`

const DataSummary = styled(_DataSummary)`
  @media (max-width: 800px) {
    width: 50%;
  }
`

import styled from "@emotion/styled"
import { FC } from "react"
import { Statistic, StatisticProps } from "./Statistic"

export type StatisticsProps = { statistics: StatisticProps[] }

export const Statistics: FC<StatisticsProps> = ({ statistics }) => (
  <Container>
    {statistics.map((s) => (
      <Statistic key={s.label} {...s} />
    ))}
  </Container>
)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  width: 300px;
`

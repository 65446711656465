import styled from "@emotion/styled"
import { User } from "@firebase/auth"
import { FC } from "react"
import { Image } from "react-actors"
import { Styles } from "../../../style"

export type HeaderUserProps = {
  user: User
}

export const HeaderUser: FC<HeaderUserProps> = ({ user }) => {
  return (
    <Icon
      width={Styles.fontSize * 3}
      height={Styles.fontSize * 3}
      src={user.photoURL ?? "hello"}
    />
  )
}

const Icon = styled(Image)`
  & > .img {
    border-radius: 1.5rem;
  }
`

import styled from "@emotion/styled"
import { IconLogout, IconSettings } from "@tabler/icons"
import { signOut } from "firebase/auth"
import { FC } from "react"
import { Link } from "react-router-dom"
import { useI18N, useNormal } from "../../../hooks"
import { Signs } from "../../../routes/signs"
import { firebaseAuth } from "../../../services"
import { HeaderStyles } from "../../organisms"
import { ClickDetector } from "../ClickDetector"

export type PopupProps = {
  close: () => void
}

export const DropDown: FC<PopupProps> = ({ close }) => {
  const { t } = useI18N()
  return (
    <ClickDetector onClick={() => close()}>
      <Box>
        <Link to={Signs.Setting}>
          <LinkBox>
            <IconSettings />
            {t("Menu", "Setting")}
          </LinkBox>
        </Link>
        <button onClick={() => signOut(firebaseAuth)}>
          <LinkBox>
            <IconLogout />
            {t("Auth", "Logout")}
          </LinkBox>
        </button>
      </Box>
    </ClickDetector>
  )
}

const Box = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: calc(${HeaderStyles.height} - 0.2rem);
  right: 0.8rem;
  background-color: ${({ theme }) => useNormal(theme).backgroundImportant};
  color: ${({ theme }) => useNormal(theme).font};
  box-shadow: 0 0.5rem 1.5rem
    ${({ theme }) => useNormal(theme).backgroundImportant};
  z-index: 10000;
  border-radius: 0.5rem;
  overflow: hidden;
`

const LinkBox = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0.75rem 1rem;

  &:hover {
    background-color: ${({ theme }) => useNormal(theme).backgroundSecondary};
  }
`

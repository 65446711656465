import { getAnalytics } from "firebase/analytics"
import { initializeApp } from "firebase/app"
import { getAuth, GoogleAuthProvider } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { getFunctions } from "firebase/functions"

const firebaseConfig = {
  apiKey: "AIzaSyD2fKLL-chIzS6SPYXZUQNRbdRoKzXgVHk",
  authDomain: "auth.nftking.jp",
  projectId: "nft-king-12fd5",
  storageBucket: "nft-king-12fd5.appspot.com",
  messagingSenderId: "242690104487",
  appId: "1:242690104487:web:d553582b68fb3f45b24f82",
  measurementId: "G-S8CQG322D5",
}

export const firebaseApp = initializeApp(firebaseConfig)
export const firebaseAnalytics =
  process.env.NODE_ENV === "production"
    ? getAnalytics(firebaseApp)
    : { app: firebaseApp }
export const firebaseAuth = getAuth(firebaseApp)
export const firebaseGoogleProvider = new GoogleAuthProvider()
export const firebaseFirestore = getFirestore(firebaseApp)
export const firebaseFunctions = getFunctions(firebaseApp, "asia-northeast1")

import styled from "@emotion/styled"
import { User } from "@firebase/auth"
import { IconMenu2 } from "@tabler/icons"
import { FC, MouseEventHandler, useState } from "react"
import { createPortal } from "react-dom"
import { LightDarkToggle } from "react-light-dark-toggle"
import { useNormal, usePersistentMode } from "../../../hooks"
import { Signs } from "../../../routes/signs"
import { DropDown } from "../../atoms"
import { MainTitle } from "../../molecules"
import { SubscribeContainer } from "../../organizations/SubscribeContainer"
import { HeaderStyles } from "./constants"
import { HeaderLogin } from "./HeaderLogin"
import { HeaderUser } from "./HeaderUser"

export type HeaderProps = {
  user?: User
  onClickMenu?: MouseEventHandler
  onLogin?: () => Promise<unknown>
}

export const Header: FC<HeaderProps> = ({ user, onClickMenu, onLogin }) => {
  const [mode, setMode] = usePersistentMode()
  const [dropdownOpen, setDropdownOpen] = useState(false)
  return (
    <>
      <Box>
        <TitleBox>
          <Menu
            onClick={onClickMenu}
            size={`calc(${HeaderStyles.height} - ${HeaderStyles.padding} * 2)`}
          />
          <MainTitle to={user ? Signs.Mypage : Signs.Index} mode={mode} />
        </TitleBox>
        <Sub>
          <LightDarkToggle
            onToggle={(isLight: boolean) => {
              setMode(isLight ? "light" : "dark")
            }}
            isLight={mode === "light"}
          ></LightDarkToggle>
          {user && <SubscribeContainer />}
          {user ? (
            <button
              onClick={(event) => {
                event.stopPropagation()
                event.preventDefault()
                setDropdownOpen(!dropdownOpen)
              }}
            >
              <HeaderUser user={user} />
            </button>
          ) : (
            <HeaderLogin onClick={onLogin} />
          )}
        </Sub>
      </Box>
      {dropdownOpen &&
        createPortal(
          <DropDown close={() => setDropdownOpen(false)} />,
          document.getElementById("portal-root")!
        )}
    </>
  )
}

const Box = styled.nav`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: ${HeaderStyles.boxShadow};
  padding: ${HeaderStyles.padding};
  height: ${HeaderStyles.height};
  background-color: ${({ theme }) => useNormal(theme).background};
  z-index: 10000;
`

const TitleBox = styled.div`
  display: flex;
  align-items: center;
  gap: ${HeaderStyles.padding};
`

const Menu = styled(IconMenu2)`
  display: none;
  @media (max-width: 800px) {
    display: block;
  }
`

const Sub = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`

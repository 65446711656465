import styled from "@emotion/styled"
import { FC } from "react"
import { Image } from "react-actors/images"
import { useI18N, useNormal } from "../../../hooks"
import { Button } from "../../atoms"

export type PlanTemplateCardProps = {
  title: "Basic" | "Load" | "King"
  value: number
  infos: string[]
  current: boolean
  onClick: () => void
}

export const PlanTemplateCard: FC<PlanTemplateCardProps> = ({
  title,
  value,
  infos,
  current,
  onClick,
}) => {
  const { t, join, builtIn } = useI18N()
  const name = t("Plan", title as "Basic" | "Load" | "King")
  return (
    <Card current={current}>
      <CardTitle>{join(name, t("Plan", "Plan"))}</CardTitle>
      <CardValue>
        {value === 0 ? (
          <b>Free</b>
        ) : (
          <>
            $<b>{value}</b>/{t("Date", "Month")}
          </>
        )}
      </CardValue>
      <CardButtonBox>
        <Button color="primary" onClick={onClick}>
          {current
            ? builtIn(t("Plan", "Current"), t("Plan", "Plan"))
            : builtIn(
                name,
                t("Plan", "Becoming"),
                t("Plan", "BecomingOrder") as "After" | "Before"
              )}
        </Button>
      </CardButtonBox>
      <CardInfoBox>
        {infos.map((info) => (
          <CardInfo>
            <Image height={16} width={16} src="/images/check.png" />
            <CardInfoText>{info}</CardInfoText>
          </CardInfo>
        ))}
      </CardInfoBox>
    </Card>
  )
}

const Card = styled.div<{ current: boolean }>`
  width: 100%;
  border-radius: 2rem;
  padding: 1.5rem 2rem;
  background-color: ${({ theme }) => useNormal(theme).backgroundSecondary};
  ${({ current }) => current && "border: 1px solid #E8871C;"}
`

const CardTitle = styled.p`
  font-size: 1.75rem;
  font-weight: bold;
`

const CardValue = styled.p`
  margin-top: 1rem;
`

const CardButtonBox = styled.div`
  margin-top: 1.5rem;
`
const CardInfoBox = styled.div`
  margin-top: 1.5rem;
`
const CardInfo = styled.div`
  display: flex;
  margin-top: 0.5rem;
`
const CardInfoText = styled.p`
  margin-left: 0.5rem;
`

import {
  getCurrentUserSubscriptions,
  Subscription,
} from "@stripe/firestore-stripe-payments/lib/subscription"
import { useQuery } from "react-query"
import { payments } from "../services/stripe"

export const useSubscription = () =>
  useQuery<Subscription[]>("Subscription", () =>
    getCurrentUserSubscriptions(payments, { status: "active" })
  )

import { httpsCallable } from "firebase/functions"
import { FC } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import {
  useCoins,
  useMultiFetcher,
  useNetworks,
  usePresets,
  useWallets,
} from "../../hooks"
import { firebaseAuth, firebaseFunctions } from "../../services"
import { AppCreateTemplate, LoadingTemplate } from "../templates"

export const AppCreatePage: FC = () => {
  const push = useNavigate()
  const [user] = useAuthState(firebaseAuth)
  const { data, isLoading, isError } = useMultiFetcher({
    coins: useCoins(),
    networks: useNetworks(),
    presets: usePresets(),
    wallets: useWallets(user),
  })
  return isLoading || isError ? (
    <LoadingTemplate />
  ) : (
    <AppCreateTemplate
      networks={data.networks!}
      coins={data.coins!}
      wallets={data.wallets!}
      presets={data.presets!}
      onCreate={(app) =>
        httpsCallable(
          firebaseFunctions,
          "createApp"
        )(app)
          .then(({ data }) => push(`/apps/${data}`))
          .catch((e) => {
            console.error(e)
            toast.error(e)
            throw e
          })
      }
    />
  )
}

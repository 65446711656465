import { ModeType } from "react-actors"
import { useRecoilState } from "recoil"
import { modeAtom } from "../stores/modeAtom"

const defaultValue: ModeType = "light"
const key = "mode"

export const usePersistentMode = () => {
  const savedMode: ModeType =
    (window.localStorage.getItem(key) as ModeType) ?? defaultValue

  const [, setState] = useRecoilState(modeAtom)

  const updateMode = (nemMode: ModeType) => {
    window.localStorage.setItem(key, nemMode)
    setState(nemMode)
  }

  return [savedMode, updateMode] as const
}

import styled from "@emotion/styled"
import { FC, FormEventHandler, ReactNode } from "react"
import { Button } from "react-actors/buttons"
import { useI18N } from "../../../hooks"

export type FormProps = {
  forms: { key?: string; label: ReactNode; form: ReactNode }[]
  isSubmitting?: boolean
  onCreate?: FormEventHandler<HTMLFormElement>
  onEdit?: FormEventHandler<HTMLFormElement>
}

export const TableForm: FC<FormProps> = ({
  forms,
  isSubmitting,
  onCreate,
  onEdit,
}) => {
  const { t } = useI18N()
  return (
    <form onSubmit={onCreate ? onCreate : onEdit}>
      {forms.map(({ key, label, form }, index) => (
        <Row key={key ?? index}>
          <Label>{label}</Label>
          <Form>{form}</Form>
        </Row>
      ))}
      <Row>
        <Button type="submit" loading={isSubmitting}>
          {onCreate ? t("Actions", "Create") : t("Actions", "Update")}
        </Button>
      </Row>
    </form>
  )
}

const Row = styled.div`
  display: flex;
  justify-content: right;
  & + & {
    margin-top: 0.5rem;
  }
  &:last-of-type {
    margin-top: 1rem;
  }
`

const Label = styled.label`
  display: flex;
  align-items: center;
  font-weight: bold;
  width: 20rem;
`

const Form = styled.div`
  width: 100%;
  margin-left: 1rem;
`

import { collection, getDocs } from "firebase/firestore"
import { useQuery } from "react-query"
import { UseQueryResult } from "react-query/types/react/types"
import { toast } from "react-toastify"
import { NetworkModel } from "../../types/model"
import { firebaseFirestore } from "../services"

export const useNetworks = (): UseQueryResult<NetworkModel[]> =>
  useQuery("Networks", () =>
    getDocs(collection(firebaseFirestore, "networks"))
      .then((data) => {
        const models: NetworkModel[] = []
        data.forEach((datum) =>
          models.push({ ...datum.data(), id: datum.id } as NetworkModel)
        )
        return models
      })
      .catch((e) => {
        console.error(e)
        toast.error(e)
      })
  )

import { IconCrown, IconCrownOff } from "@tabler/icons"
import { FC } from "react"
import { Link } from "react-router-dom"
import { useSubscription } from "../../hooks/useSubscription"
import { Signs } from "../../routes/signs"

export const SubscribeContainer: FC = () => {
  const { data } = useSubscription()
  return (
    <Link to={Signs.Plan}>
      {data && data.length ? (
        <IconCrown size="2rem" />
      ) : (
        <IconCrownOff size="2rem" />
      )}
    </Link>
  )
}

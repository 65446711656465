import styled from "@emotion/styled"
import { subMonths } from "date-fns"
import { FC } from "react"
import { Button } from "react-actors/buttons"
import { Select as _Select } from "react-actors/inputs"
import { NavLink, Outlet, useMatch, useNavigate } from "react-router-dom"
import { AppModel, CoinModel } from "../../../../types/model"
import { useI18N } from "../../../hooks"
import { Content, Media } from "../../molecules"
import { AppCumulativeGainSummaryContainer } from "../../organizations"

export type AppDetailTemplateProps = {
  id: string
  coins: CoinModel[]
  data: AppModel
  onRefresh?: () => Promise<unknown>
  onUpdate?: () => Promise<unknown> | unknown
}

export const AppDetailTemplate: FC<AppDetailTemplateProps> = ({
  id,
  coins,
  data,
  onRefresh,
  onUpdate,
}) => {
  const { t } = useI18N()
  const matchPrice = useMatch("/apps/:id/prices")
  const matchCoin = useMatch("/apps/:id/prices/:coinId")
  const navigate = useNavigate()
  return (
    <Content
      title={data.name}
      range={subMonths(new Date(), 1)}
      side={
        <Side>
          <Button color="secondary" onClick={onRefresh}>
            {t("Actions", "Update")}
          </Button>
          <Button color="secondary" onClick={onUpdate}>
            {t("Actions", "Edit")}
          </Button>
        </Side>
      }
    >
      <AppCumulativeGainSummaryContainer appId={id} />
      <Media>
        <TabsBox>
          <Tabs>
            <Tab
              className={({ isActive }) => (isActive ? "active" : "")}
              to={`/apps/${id}`}
              end
            >
              Transactions
            </Tab>
            <Tab
              className={({ isActive }) => (isActive ? "active" : "")}
              to={`/apps/${id}/gains`}
              end
            >
              Gains
            </Tab>
            <Tab
              className={({ isActive }) => (isActive ? "active" : "")}
              to={`/apps/${id}/prices`}
            >
              Prices
            </Tab>
          </Tabs>
          {(matchPrice || matchCoin) && (
            <Select
              defaultValue={matchCoin?.params.coinId}
              onChange={({ target }) =>
                navigate(`/apps/${id}/prices/${target.value}`)
              }
            >
              {data.coinIds
                .map((coinId) => coins.find(({ id }) => id === coinId)!)
                .map((coin) => (
                  <option key={coin.id} value={coin.id}>
                    {coin.name}
                  </option>
                ))}
            </Select>
          )}
        </TabsBox>
        <Outlet />
      </Media>
    </Content>
  )
}

const Side = styled.div`
  display: flex;
  gap: 0.5rem;
`

const TabsBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.75rem;
`

const Tabs = styled.div`
  display: flex;
  gap: 1rem;
`

const Tab = styled(NavLink)`
  padding: 0.5rem;

  &.active {
    border-bottom: 2px solid #ffbb28;
  }

  &:hover {
    border-bottom: 2px solid #ffbb28;
  }
`

const Select = styled(_Select)`
  width: 15rem;
`

import { FC, ReactNode } from "react"
import { LayoutProps } from "../components"

export type LayoutSwitchProps = {
  loading: boolean
  authorized?: boolean
  layoutProps: LayoutProps
  Layout: FC<LayoutProps>
  AuthLayout: FC<LayoutProps>
  children: ReactNode
}

export const LayoutSwitch: FC<LayoutSwitchProps> = ({
  authorized,
  layoutProps,
  Layout,
  AuthLayout,
  children,
  loading,
}) => {
  return authorized ? (
    <AuthLayout {...layoutProps}>{children}</AuthLayout>
  ) : (
    <Layout {...layoutProps}>{children}</Layout>
  )
}

import { getProduct } from "@stripe/firestore-stripe-payments"
import { useQuery } from "react-query"
import { payments } from "../services/stripe"
import { useSubscription } from "./useSubscription"

export const useCurrentPlan = () => {
  const { data: subscription } = useSubscription()

  return useQuery(
    "CurrentPlan",
    () =>
      subscription?.[0]?.product
        ? getProduct(payments, subscription?.[0]?.product)
        : undefined,
    { enabled: !!subscription }
  )
}

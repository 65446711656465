import { User } from "@firebase/auth"
import { subMonths } from "date-fns"
import { orderBy } from "firebase/firestore"
import { GainModel } from "../../types/model"
import { useCollection } from "./useCollection"

export const useAppGains = (
  user: User | undefined | null,
  appId?: string,
  date: Date = subMonths(new Date(), 1)
) =>
  useCollection<GainModel>(
    ["Gains", appId, date.toDateString()],
    ["users", user?.uid, "apps", appId, "gains"],
    [orderBy("date", "desc")]
  )

import startOfDay from "date-fns/startOfDay"
import subMonths from "date-fns/subMonths"
import { orderBy, where } from "firebase/firestore"
import { UseQueryResult } from "react-query/types/react/types"
import { PriceModel } from "../../types/model"
import { useCollection } from "./useCollection"

type QueryOption = {
  min?: Date
  max?: Date
}

export const usePrices = ({
  min = subMonths(startOfDay(new Date()), 1),
  max = new Date(),
}: QueryOption): UseQueryResult<PriceModel[]> =>
  useCollection(
    ["Coins", min?.toDateString(), max?.toDateString()],
    ["prices"],
    [
      where("date", ">=", min),
      where("date", "<=", max),
      orderBy("date", "desc"),
    ]
  )

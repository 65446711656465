import styled from "@emotion/styled"
import { FC } from "react"
import { AppModel, CumulativeGainModel } from "../../../../types/model"
import { useFiatFormat, useI18N } from "../../../hooks"
import { DataSummary, Media as _Media } from "../../molecules"
import { AggregatePieChart } from "../AggregatePieChart"

export type AggregateSummaryProps = {
  apps: AppModel[]
  aggregates: CumulativeGainModel[]
}

export const AggregateSummary: FC<AggregateSummaryProps> = (props) => (
  <Box>
    <Summary {...props} dataKey="income" />
    <Summary {...props} dataKey="expenses" />
  </Box>
)

const Summary: FC<
  AggregateSummaryProps & { dataKey: "income" | "expenses" }
> = ({ apps, aggregates, dataKey }) => {
  const { t } = useI18N()
  const { format } = useFiatFormat()
  const sorted = [...aggregates].sort((a, b) => b[dataKey] - a[dataKey])
  const contents = sorted.slice(0, 8).map((gain) => ({
    key: gain.id,
    head: apps.find(({ id }) => id === gain.id)?.name,
    content: format(gain[dataKey]),
  }))
  return (
    <Media>
      <Title>
        {dataKey === "income"
          ? t("Finance", "BreakdownIncome")
          : t("Finance", "BreakdownExpenses")}
      </Title>
      <ContentBox>
        <Wrap>
          <AggregatePieChart
            apps={apps}
            aggregates={sorted}
            dataKey={dataKey}
          />
        </Wrap>
        <Wrap>
          <DataSummary contents={contents} />
        </Wrap>
      </ContentBox>
    </Media>
  )
}

const Box = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  flex-direction: row;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`

const Wrap = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Title = styled.h3`
  font-size: 1.5rem;
  font-weight: bolder;
`

const ContentBox = styled.div`
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
`

const Media = styled(_Media)`
  width: 100%;
`

import { FC } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import {
  useAggregateCumulativeGains,
  useApps,
  useMultiFetcher,
} from "../../hooks"
import { firebaseAuth } from "../../services"
import { AppListTemplate, LoadingTemplate } from "../templates"

export const AppListPage: FC = () => {
  const [user] = useAuthState(firebaseAuth)
  const { isLoading, isError, data } = useMultiFetcher({
    apps: useApps(user),
    aggregates: useAggregateCumulativeGains(user),
  })
  return isLoading || isError ? (
    <LoadingTemplate />
  ) : (
    <AppListTemplate apps={data.apps} aggregates={data.aggregates} />
  )
}

import { User } from "@firebase/auth"
import { doc, getDoc } from "firebase/firestore"
import { useQuery } from "react-query"
import { UseQueryResult } from "react-query/types/react/types"
import { toast } from "react-toastify"
import { UserModel } from "../../types/model"
import { firebaseFirestore } from "../services"

export const useUser = (
  user: User | undefined | null
): UseQueryResult<UserModel> =>
  useQuery(
    ["User"],
    () =>
      getDoc(doc(firebaseFirestore, "users", user?.uid!))
        .then((data) =>
          data.exists()
            ? ({
                ...data.data(),
                id: data.id,
              } as UserModel)
            : undefined
        )
        .catch((e) => {
          console.error(e)
          toast.error(e)
          throw e
        }),
    { enabled: !!user?.uid }
  )

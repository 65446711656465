import styled from "@emotion/styled"
import { signInWithPopup } from "firebase/auth"
import { FC, ReactElement } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { toast } from "react-toastify"
import {
  AppCreatePage,
  AppDetailPage,
  AppEditPage,
  AppListPage,
  ExtendedLayout,
  GainTableContainer,
  IndexPage,
  Layout,
  MypagePage,
  NotFoundPage,
  PlanPage,
  PriceTableContainer,
  SettingPage,
  SetupPage,
  SubscribePage,
  TransactionTableContainer,
  WalletCreatePage,
  WalletEditPage,
  WalletListPage,
} from "../components"
import { useNormal, useUser } from "../hooks"
import { firebaseAuth, firebaseGoogleProvider } from "../services"
import { LayoutSwitch } from "./LayoutSwitch"
import { Redirect } from "./Redirect"
import { Signs } from "./signs"

export const Roads: FC = () => {
  const [_user, loading] = useAuthState(firebaseAuth)
  const user = _user || undefined
  const { data, isFetched, isIdle } = useUser(user)

  const authorizeRedirectHandler = (element: ReactElement) =>
    !user && !loading ? (
      <Redirect to={Signs.Index} />
    ) : !data && isFetched ? (
      <Redirect to={Signs.Setup} />
    ) : (
      element
    )

  return (
    <>
      <BrowserRouter>
        <LayoutSwitch
          loading={loading}
          authorized={!!(user && data)}
          layoutProps={{
            user,
            onLogin: () =>
              signInWithPopup(firebaseAuth, firebaseGoogleProvider).catch(
                (err) => toast.error(err)
              ),
          }}
          Layout={ExtendedLayout}
          AuthLayout={Layout}
        >
          <Routes>
            <Route
              path={Signs.Index}
              element={user ? <Redirect to={Signs.Mypage} /> : <IndexPage />}
            />
            <Route
              path={Signs.Setup}
              element={data ? <Redirect to={Signs.Mypage} /> : <SetupPage />}
            />
            <Route
              path={Signs.Mypage}
              element={authorizeRedirectHandler(<MypagePage />)}
            />
            <Route
              path={Signs.AppCreate}
              element={authorizeRedirectHandler(<AppCreatePage />)}
            />
            <Route path={Signs.AppEdit} element={<AppEditPage />} />
            <Route
              path={Signs.AppDetail}
              element={authorizeRedirectHandler(<AppDetailPage />)}
            >
              <Route index element={<TransactionTableContainer />} />
              <Route path="gains" element={<GainTableContainer />} />
              <Route path="prices" element={<PriceTableContainer />} />
              <Route path="prices/:coinId" element={<PriceTableContainer />} />
            </Route>
            <Route
              path={Signs.AppList}
              element={authorizeRedirectHandler(<AppListPage />)}
            />
            <Route
              path={Signs.WalletList}
              element={authorizeRedirectHandler(<WalletListPage />)}
            />
            <Route
              path={Signs.WalletCreate}
              element={authorizeRedirectHandler(<WalletCreatePage />)}
            />
            <Route
              path={Signs.WalletEdit}
              element={authorizeRedirectHandler(<WalletEditPage />)}
            />
            <Route
              path={Signs.Plan}
              element={authorizeRedirectHandler(<PlanPage />)}
            />
            <Route
              path={Signs.Subscribe}
              element={authorizeRedirectHandler(<SubscribePage />)}
            />
            <Route
              path={Signs.Setting}
              element={authorizeRedirectHandler(<SettingPage />)}
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </LayoutSwitch>
      </BrowserRouter>
      <LoadingContainer loading={loading || (!isFetched && !isIdle)} />
    </>
  )
}

export const LoadingContainer = styled.div<{ loading: boolean }>`
  pointer-events: none;
  background-color: ${({ theme }) => useNormal(theme).background};
  z-index: 10000000;
  opacity: ${({ loading }) => (loading ? 1 : 0)};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import { subMonths } from "date-fns"
import formatInTimeZone from "date-fns-tz/formatInTimeZone"
import getTimezoneOffset from "date-fns-tz/getTimezoneOffset"
import startOfDay from "date-fns/startOfDay"
import { FC } from "react"
import {
  Bar,
  Brush,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { CumulativeGainModel, GainModel } from "../../../../types/model"
import { useFiatFormat, useI18N, useNormal } from "../../../hooks"
import { isNumber } from "../../../utils/is"

export type CumulativeGraphProps = {
  cumulatives?: CumulativeGainModel[]
  gains?: GainModel[]
  before?: CumulativeGainModel
  range: number
}

const getData = (
  cumulatives: CumulativeGainModel[],
  gains: GainModel[],
  before: CumulativeGainModel | undefined,
  range: number
) => {
  const date = startOfDay(new Date())
  const firstDate =
    range !== Number.POSITIVE_INFINITY ? subMonths(date, range) : undefined
  const offset = getTimezoneOffset(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  )
  const diff = 1000 * 60 * 60 * 24 - offset
  return [
    ...(before &&
    firstDate &&
    cumulatives[0].date.toMillis() !== firstDate.getTime()
      ? [
          {
            cumulative: before.net,
            date: firstDate.getTime(),
          },
        ]
      : []),
    ...cumulatives.map((cumulative) => {
      const gain = gains.find((gain) => gain.id === cumulative.id)?.net ?? 0
      return {
        cumulative: cumulative.net,
        date: cumulative.date.toMillis(),
        ...(gain < 0 ? { expenses: gain } : { income: gain }),
      }
    }),
    ...(cumulatives[cumulatives.length - 1].date.toMillis() !==
    date.getTime() - diff
      ? [
          {
            cumulative: cumulatives[cumulatives.length - 1].net,
            date: date.getTime(),
          },
        ]
      : []),
  ]
}

export const CumulativeGraph: FC<CumulativeGraphProps> = ({
  cumulatives,
  gains,
  before,
  range,
}) => {
  const { t } = useI18N()
  const theme = useTheme()
  const { backgroundSecondary: backgroundColor, font } = useNormal(theme)
  const { format: fiat } = useFiatFormat()
  if (!cumulatives || !cumulatives.length || !gains || !gains.length)
    return <NotFound>Data Not Found</NotFound>

  return (
    <Responsive width="100%" height={350}>
      <ComposedChart data={getData(cumulatives, gains, before, range)}>
        <defs>
          <linearGradient id="Line" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FDBC14" />
            <stop offset="1" stopColor="#F88C16" />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="date"
          padding={{ left: 5, right: 5 }}
          domain={["dataMin", "dataMax"]}
          tickFormatter={(unixTime) =>
            formatInTimeZone(new Date(unixTime), "UTC", "M/d")
          }
          type="number"
          scale="time"
          tick={{ fill: font }}
        />
        <YAxis
          yAxisId="total"
          allowDecimals={false}
          tickFormatter={(value) =>
            Math.abs(value) >= 10000
              ? `${value / 1000}${t("Math", "Kilo")}`
              : value
          }
          tick={{ fill: font }}
        />
        <YAxis
          yAxisId="gain"
          orientation="right"
          allowDecimals={false}
          tickFormatter={(value) =>
            Math.abs(value) >= 10000
              ? `${value / 1000}${t("Math", "Kilo")}`
              : value
          }
          tick={{ fill: font }}
        />
        <CartesianGrid stroke="#eee" />
        <Bar
          yAxisId="gain"
          stackId="gain"
          name={t("Finance", "Income")}
          dataKey="income"
          barSize={10}
          fill="#6DE99E"
        />
        <Bar
          yAxisId="gain"
          stackId="gain"
          name={t("Finance", "Expenses")}
          dataKey="expenses"
          barSize={10}
          fill="#D85473"
        />
        <Line
          type="monotone"
          yAxisId="total"
          isAnimationActive={false}
          name={t("Finance", "CumulativeNet")}
          dataKey="cumulative"
          strokeWidth={3}
          stroke="url(#Line)"
        />
        <Brush
          dataKey="date"
          height={30}
          travellerWidth={15}
          leaveTimeOut={99999}
          tickFormatter={(time) =>
            formatInTimeZone(new Date(time), "UTC", "M/d")
          }
        />
        <Tooltip
          itemSorter={(item) => (item.dataKey === "cumulative" ? 0 : 1)}
          wrapperStyle={{
            color: font,
            outline: "none",
          }}
          labelStyle={{
            fontWeight: "bold",
            marginBottom: ".25rem",
          }}
          contentStyle={{
            backgroundColor,
            borderColor: "#E8871C",
            borderRadius: ".25rem",
            borderWidth: "1.5px",
          }}
          itemStyle={{
            display: "flex",
            justifyContent: "flex-start",
          }}
          labelFormatter={(value) =>
            formatInTimeZone(new Date(value), "UTC", "M/d")
          }
          formatter={(value) => (isNumber(value) ? fiat(value) ?? "" : "")}
        />
      </ComposedChart>
    </Responsive>
  )
}

const Responsive = styled(ResponsiveContainer)`
  & .recharts-cartesian-axis-tick {
    font-family: "Roboto Condensed", sans-serif;
  }
  & .recharts-tooltip-item > span {
    display: block;
  }

  & .recharts-tooltip-item-separator {
    margin: 0 0.25rem;
  }

  & .recharts-tooltip-item-value {
    margin-left: auto;
  }
`

const NotFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  width: 100%;
  height: 300px;
  border: 1px solid ${({ theme }) => useNormal(theme).border};
`

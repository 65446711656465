import { subMonths } from "date-fns"
import { FC } from "react"
import {
  AppModel,
  CumulativeGainModel,
  GainModel,
} from "../../../../types/model"
import { useI18N } from "../../../hooks"
import { Content, Media } from "../../molecules"
import { TotalNetGraph } from "../../organisms"
import {
  AggregateSummaryContainer,
  CumulativeGainSummaryContainer,
} from "../../organizations"

export type MypageTemplateProps = {
  apps?: AppModel[]
  aggregates?: CumulativeGainModel[]
  cumulativeGains?: CumulativeGainModel[]
  lastCumulativeGain?: CumulativeGainModel
  gains?: GainModel[]
}

export const MypageTemplate: FC<MypageTemplateProps> = ({
  apps,
  aggregates,
}) => {
  const { t } = useI18N()
  return (
    <Content title={t("Menu", "Mypage")} range={subMonths(new Date(), 1)}>
      <CumulativeGainSummaryContainer />
      <AggregateSummaryContainer />
      <Media>
        <TotalNetGraph apps={apps} aggregates={aggregates} />
      </Media>
    </Content>
  )
}

import styled from "@emotion/styled"
import { FC, ReactNode } from "react"

export type CurrencyProps = {
  children: ReactNode
  className?: string
}

export const Currency: FC<CurrencyProps> = ({ className, children }) => (
  <Box className={className}>{children}</Box>
)

const Box = styled.span`
  font-family: "Roboto Condensed", sans-serif;
`

import { User } from "@firebase/auth"
import { subMonths } from "date-fns"
import { orderBy } from "firebase/firestore"
import { TransactionModel } from "../../types/model"
import { useCollection } from "./useCollection"

export const useTransactions = (
  user: User | undefined | null,
  appId?: string,
  date: Date = subMonths(new Date(), 1)
) =>
  useCollection<TransactionModel>(
    ["Transactions", appId, date.toDateString()],
    ["users", user?.uid, "apps", appId, "transactions"],
    [orderBy("time", "desc")]
  )

import { deleteDoc, doc, setDoc } from "firebase/firestore"
import { FC } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { useNavigate, useParams } from "react-router-dom"
import { queryClient } from "../../App"
import { useWallet } from "../../hooks/useWallet"
import { Signs } from "../../routes/signs"
import { firebaseAuth, firebaseFirestore } from "../../services"
import { LoadingTemplate, WalletEditTemplate } from "../templates"

export const WalletEditPage: FC = () => {
  const push = useNavigate()
  const [user] = useAuthState(firebaseAuth)
  const { address } = useParams()
  const { data, isLoading, isError } = useWallet(user, address!)
  return isLoading || isError || !data ? (
    <LoadingTemplate />
  ) : (
    <WalletEditTemplate
      data={data!}
      onDelete={async (d) => {
        await deleteDoc(
          doc(firebaseFirestore, "users", user?.uid!, "wallets", d.address)
        )
        await queryClient.invalidateQueries(["Wallets", address])
        return push(Signs.WalletList)
      }}
      onUpdate={async (d) => {
        await setDoc(
          doc(firebaseFirestore, "users", user?.uid!, "wallets", d.address),
          d
        )
        await queryClient.invalidateQueries(["Wallets", address])
        return push(Signs.WalletList)
      }}
    />
  )
}

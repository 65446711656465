import { User } from "@firebase/auth"
import { limit, orderBy, where } from "firebase/firestore"
import { UseQueryResult } from "react-query/types/react/types"
import { CumulativeGainModel } from "../../types/model"
import { useCollection } from "./useCollection"

export const useLastCumulativeGain = (
  user: User | undefined | null,
  date: Date = new Date()
): UseQueryResult<CumulativeGainModel | undefined> => {
  const result = useCollection<CumulativeGainModel>(
    ["CumulativeGains", date.toDateString()],
    ["users", user?.uid, "cumulative_gains"],
    [where("date", "<", date), orderBy("date", "desc"), limit(1)]
  )
  return {
    ...result,
    data: result.data?.length ? result.data[0] : undefined,
  } as UseQueryResult<CumulativeGainModel | undefined>
}

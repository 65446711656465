import { User } from "@firebase/auth"
import { subMonths } from "date-fns"
import { where } from "firebase/firestore"
import { CumulativeGainModel } from "../../types/model"
import { useCollection } from "./useCollection"

export const useAppCumulativeGains = (
  user: User | undefined | null,
  appId?: string,
  date: Date = subMonths(new Date(), 1)
) =>
  useCollection<CumulativeGainModel>(
    ["CumulativeGains", appId, date.toDateString()],
    ["users", user?.uid, "apps", appId, "cumulative_gains"],
    [where("date", ">=", date)]
  )

import { FC, ReactNode, useEffect } from "react"

export type ClickDetectorProps = {
  children?: ReactNode
  onClick: () => Promise<unknown> | unknown
}

export const ClickDetector: FC<ClickDetectorProps> = ({
  children,
  onClick,
}) => {
  useEffect(() => {
    const listener = async () => {
      await onClick()
    }
    document.addEventListener("click", listener)
    return () => document.removeEventListener("click", listener)
  })
  return <>{children}</>
}

import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import { FC } from "react"
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { AppModel, CumulativeGainModel } from "../../../../types/model"
import { useFiatFormat, useI18N, useNormal } from "../../../hooks"
import { isNumber } from "../../../utils/is"

export type TotalNetGraphProps = {
  apps?: AppModel[]
  aggregates?: CumulativeGainModel[]
  className?: string
}

const getData = (aggregates: CumulativeGainModel[]) =>
  aggregates.map((agg) =>
    agg.net >= 0
      ? {
          id: agg.id,
          income: agg.net,
        }
      : {
          id: agg.id,
          expenses: agg.net,
        }
  )

export const TotalNetGraph: FC<TotalNetGraphProps> = ({
  apps,
  aggregates,
  className,
}) => {
  const theme = useTheme()
  const { backgroundSecondary: backgroundColor, font } = useNormal(theme)
  const { format } = useFiatFormat()
  const { t } = useI18N()
  return (
    <Box className={className}>
      <Title>{t("Component", "TotalNet")}</Title>
      <Responsive height={300}>
        <BarChart data={getData(aggregates ?? [])} maxBarSize={50}>
          <CartesianGrid />
          <XAxis
            dataKey="id"
            tickFormatter={(appId: string) => {
              const name = apps?.find(({ id }) => id === appId)?.name ?? ""
              const limit = 5

              return name.length > limit
                ? `${name.substring(0, limit)}...`
                : name
            }}
            tick={{ fill: font }}
          />
          <YAxis
            tickFormatter={(value) =>
              Math.abs(value) >= 100000
                ? `${value / 1000}${t("Math", "Kilo")}`
                : value
            }
            tick={{ fill: font }}
          />
          <Bar
            stackId="net"
            name={t("Finance", "Income")}
            dataKey="income"
            fill="#6DE99E"
          />
          <Bar
            stackId="net"
            name={t("Finance", "Expenses")}
            dataKey="expenses"
            fill="#D85473"
          />
          <Tooltip
            cursor={{ opacity: ".2" }}
            wrapperStyle={{ color: font, outline: "none" }}
            labelStyle={{
              fontWeight: "bold",
              marginBottom: ".25rem",
            }}
            contentStyle={{
              backgroundColor,
              borderColor: "#E8871C",
              borderRadius: ".25rem",
              borderWidth: "1.5px",
            }}
            formatter={(value) => [isNumber(value) ? format(value) ?? "" : ""]}
            labelFormatter={(appId) =>
              apps?.find(({ id }) => id === appId)?.name ?? ""
            }
          />
        </BarChart>
      </Responsive>
    </Box>
  )
}

const Box = styled.div``

const Title = styled.h3`
  font-size: 1.5rem;
  font-weight: bolder;
  margin-bottom: 1rem;
`

const Responsive = styled(ResponsiveContainer)`
  & .recharts-cartesian-axis-tick-value[orientation="left"] {
    font-family: "Roboto Condensed", sans-serif;
  }
  & .recharts-tooltip-item-value {
    display: inline-block;
    width: 100%;
    text-align: right;
  }
`

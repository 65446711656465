import styled from "@emotion/styled"
import { FC, ReactNode } from "react"
import { useNormal } from "../../../hooks"

export type MediaProps = {
  className?: string
  children: ReactNode
}

export const Media: FC<MediaProps> = ({ className, children }) => (
  <Box className={className}>{children}</Box>
)

const Box = styled.div`
  border-radius: 1rem;
  padding: 1rem;
  background-color: ${({ theme }) => useNormal(theme).backgroundSecondary};
`

import { useTheme } from "@emotion/react"
import { FC } from "react"
import { Cell, Pie, PieChart, Tooltip } from "recharts"
import { AppModel, CumulativeGainModel } from "../../../../types/model"
import { useFiatFormat, useNormal } from "../../../hooks"

export type AggregatePieChartProps = {
  apps: AppModel[]
  aggregates: CumulativeGainModel[]
  dataKey: keyof CumulativeGainModel
  size?: number
}

const COLORS = ["#FF931D", "#FFD66B", "#EDC36D"]

export const AggregatePieChart: FC<AggregatePieChartProps> = ({
  aggregates,
  apps,
  dataKey,
  size = 160,
}) => {
  const theme = useTheme()
  const { backgroundSecondary: backgroundColor, font } = useNormal(theme)
  const { format } = useFiatFormat()
  return (
    <PieChart width={size} height={size}>
      <Pie
        data={aggregates}
        dataKey={dataKey}
        nameKey="id"
        innerRadius={size / 2 - size / 6}
        outerRadius={size / 2}
        startAngle={90}
        endAngle={-270}
        stroke="none"
      >
        {aggregates?.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip
        wrapperStyle={{ outline: "none" }}
        labelStyle={{
          fontWeight: "bold",
        }}
        contentStyle={{
          backgroundColor,
          borderColor: "#E8871C",
          borderRadius: ".25rem",
          borderWidth: "1.5px",
        }}
        itemStyle={{ color: font }}
        formatter={(value, appId) => [
          format(value as number) ?? "",
          apps?.find(({ id }) => id === appId)?.name ?? "",
        ]}
      />
    </PieChart>
  )
}

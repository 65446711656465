import { User } from "@firebase/auth"
import { orderBy } from "firebase/firestore"
import { CumulativeGainModel } from "../../types/model"
import { useCollection } from "./useCollection"

export const useAggregateCumulativeGains = (user: User | undefined | null) =>
  useCollection<CumulativeGainModel>(
    ["AggregateCumulativeGains"],
    ["users", user?.uid, "aggregate_cumulative_gains"],
    [orderBy("net", "desc")]
  )

import { FC } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import {
  useAggregateCumulativeGains,
  useApps,
  useCumulativeGains,
  useGains,
  useLastCumulativeGain,
  useMultiFetcher,
} from "../../hooks"
import { firebaseAuth } from "../../services"
import { LoadingTemplate, MypageTemplate } from "../templates"

export const MypagePage: FC = () => {
  const [user] = useAuthState(firebaseAuth)
  const { data, isLoading, isError } = useMultiFetcher({
    cumulativeGains: useCumulativeGains(user),
    aggregates: useAggregateCumulativeGains(user),
    lastCumulativeGain: useLastCumulativeGain(user),
    apps: useApps(user),
    gains: useGains(user),
  })
  return isLoading || isError ? (
    <LoadingTemplate />
  ) : (
    <MypageTemplate
      apps={data.apps}
      aggregates={data.aggregates}
      cumulativeGains={data.cumulativeGains}
      lastCumulativeGain={data.lastCumulativeGain}
      gains={data.gains}
    />
  )
}

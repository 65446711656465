import styled from "@emotion/styled"
import { FC } from "react"
import { Button } from "react-actors/buttons"
import { Input } from "react-actors/inputs"
import { useForm } from "react-hook-form"
import { WalletModel } from "../../../../types/model"
import { useI18N } from "../../../hooks"

export type WalletCreateTemplateProps = {
  onCreate: (wallet: WalletModel) => Promise<unknown>
}

export const WalletCreateTemplate: FC<WalletCreateTemplateProps> = ({
  onCreate,
}) => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<WalletModel>({ mode: "onBlur" })
  const { t } = useI18N()
  return (
    <div>
      <form onSubmit={handleSubmit(onCreate)}>
        <Table>
          <tbody>
            <Row>
              <th>{t("Wallet", "Address")}</th>
              <td>
                <Input
                  error={!!errors.address}
                  {...register("address", {
                    required: true,
                    pattern: {
                      value: /^0x[a-zA-Z\d]{40}$/g,
                      message: "Invalid address pattern",
                    },
                  })}
                />
              </td>
            </Row>
            {errors.address && (
              <Row>
                <td colSpan={2}>{errors.address.message}</td>
              </Row>
            )}
            <Row>
              <th>{t("Wallet", "Label")}</th>
              <td>
                <Input error={!!errors.label} {...register("label")} />
              </td>
            </Row>
          </tbody>
        </Table>
        <Button loading={isSubmitting} type="submit">
          {t("Actions", "Create")}
        </Button>
      </form>
    </div>
  )
}

const Table = styled.table`
  width: 100%;
`

const Row = styled.tr`
  & + & {
    padding-top: 0.5rem;
  }
`

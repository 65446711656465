import styled from "@emotion/styled"
import { doc, setDoc } from "firebase/firestore"
import { FC } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { toast } from "react-toastify"
import { UserForm } from "../../../types/model"
import { useUser } from "../../hooks"
import { firebaseAuth, firebaseFirestore } from "../../services"
import { SideMenuStyles } from "../organisms"
import { LoadingTemplate, SettingTemplate } from "../templates"

export const SetupPage: FC = () => {
  const [auth] = useAuthState(firebaseAuth)
  const { data: user, refetch, isLoading, isError } = useUser(auth)
  console.log(auth)
  return isLoading || isError ? (
    <LoadingTemplate />
  ) : (
    <Content>
      <SettingTemplate
        user={auth!}
        model={user}
        onCreate={(model: UserForm) =>
          setDoc(doc(firebaseFirestore, "users", auth?.uid!), model, {
            merge: true,
          })
            .then(() => refetch())
            .catch((e) => toast.error(`Create failed.\n${e}`))
        }
      />
    </Content>
  )
}

const Content = styled.div`
  padding: 1rem ${SideMenuStyles.width};
`

import { FC } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import {
  useAppCumulativeGains,
  useAppGains,
  useLastAppCumulativeGain,
  useMultiFetcher,
} from "../../hooks"
import { useDateRangePlan } from "../../hooks/useDateRangePlan"
import { firebaseAuth } from "../../services"
import {
  CumulativeGainSummary,
  CumulativeGainSummaryLoading,
} from "../organisms"

export type AppCumulativeGainSummaryContainerProps = {
  appId: string
}

export const AppCumulativeGainSummaryContainer: FC<
  AppCumulativeGainSummaryContainerProps
> = ({ appId }) => {
  const [user] = useAuthState(firebaseAuth)
  const {
    data: { date, range },
  } = useDateRangePlan()
  const { data, isLoading, isIdle, isError } = useMultiFetcher({
    cumulatives: useAppCumulativeGains(user, appId, date),
    gains: useAppGains(user, appId, date),
    last: useLastAppCumulativeGain(user, appId),
    before: useLastAppCumulativeGain(user, appId, date),
  })
  return isIdle ? (
    <CumulativeGainSummaryLoading />
  ) : isLoading ? (
    <CumulativeGainSummaryLoading />
  ) : isError ? (
    <CumulativeGainSummaryLoading />
  ) : (
    <CumulativeGainSummary
      cumulatives={data.cumulatives}
      gains={data.gains}
      last={data.last!}
      before={data.before}
      range={range}
    />
  )
}

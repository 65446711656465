import styled from "@emotion/styled"
import { signInWithPopup } from "firebase/auth"
import { FC } from "react"
import { Image, useMode } from "react-actors"
import { toast } from "react-toastify"
import { useNormal } from "../../hooks"
import { firebaseAuth, firebaseGoogleProvider } from "../../services"

export const IndexPage: FC = () => {
  const mode = useMode()
  return (
    <Wrap>
      <Jumbotron>
        <Main>
          <Title>
            <Crown src="/images/crown.png" width={56} height={44} />
            NFTゲーム王に、俺はなる。
          </Title>
          <Detail>
            NFT KINGは、全てのNFTゲームの収支を一元管理して、
            <br />
            日本円での予測資産を確認することができます。
          </Detail>
        </Main>
        <Image src="/images/graph.png" width={503} height={277} />
      </Jumbotron>
      <CardBox>
        <Card>
          <Image src="/images/nft-coin.png" width={80} height={80} />
          <CardText>
            <Emphasis>NFTゲーム</Emphasis>の<br />
            利益を一発確認
          </CardText>
        </Card>
        <Card>
          <Image src="/images/yen-coin.png" width={80} height={80} />
          <CardText>
            <Emphasis>日本円</Emphasis>で<br />
            予測資産の表示
          </CardText>
        </Card>
        <Card>
          <Image src="/images/game-coin.png" width={80} height={80} />
          <CardText>
            <Emphasis>全てのゲーム</Emphasis>の<br />
            収支を一括管理
          </CardText>
        </Card>
      </CardBox>
      <GoldCard>
        <p>
          いろんなP2EやM2Eやってると、トータルでどのくらい勝ったかわからない...
          <br />
          そんな問題を一発解決
        </p>
        <br />
        <p>Binanceネットワークを利用した幅広いゲームに対応</p>
        <GoldCardButtonBox>
          <GoldCardButton
            onClick={() =>
              signInWithPopup(firebaseAuth, firebaseGoogleProvider).catch(
                (err) => toast.error(err)
              )
            }
          >
            はじめる
          </GoldCardButton>
        </GoldCardButtonBox>
      </GoldCard>
      <RoadmapBox>
        <RoadmapTitle>Roadmap</RoadmapTitle>
        <RoadmapContent>
          <RoadmapRow>
            <RoadmapPoint />
            in-game portfolio
          </RoadmapRow>
          <RoadmapRow>
            <RoadmapPoint />
            solana network support
          </RoadmapRow>
          <RoadmapRow>
            <RoadmapPoint />
            etheriam network support
          </RoadmapRow>
        </RoadmapContent>
      </RoadmapBox>
      <Footer>
        <Image
          src={mode === "light" ? "/logo.png" : "/logo-white.png"}
          width={149}
          height={32}
        />
        <FooterCopyright>© 2022 NFT KING. ALL RIGHTS RESERVED.</FooterCopyright>
      </Footer>
    </Wrap>
  )
}

const Wrap = styled.div`
  max-width: 1080px;
`

const Jumbotron = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 6.5rem;
  width: 1080px;
`

const Main = styled.div`
  width: 42rem;
  margin-right: -5rem;
`

const Crown = styled(Image)`
  position: absolute;
  top: -1rem;
  left: 28rem;
`

const Title = styled.h2`
  position: relative;
  font-weight: bold;
  font-size: 5.25rem;
  line-height: 8rem;
`

const Detail = styled.p`
  margin-top: 2rem;
  font-size: 1.25rem;
  line-height: 2.5rem;
`

const CardBox = styled.div`
  margin-top: 3rem;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
`

const Card = styled.div`
  width: 100%;
  padding: 1.5rem;
  border-radius: 2rem;
  background-color: ${({ theme }) => useNormal(theme).backgroundSecondary};
`

const CardText = styled.p`
  margin-top: 2rem;
  font-size: 1.5rem;
  line-height: 2.5rem;
`

const Emphasis = styled.span`
  color: #e8871c;
  font-size: 2rem;
  font-weight: bold;
`

const GoldCard = styled.div`
  color: #fff;
  padding: 5rem 2.5rem;
  margin-top: 3rem;
  background-image: url("/images/gold-card.png");
  background-size: cover;
  height: 23rem;
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 2.25rem;
  text-align: center;
`

const GoldCardButtonBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
`

const GoldCardButton = styled.button`
  padding: 1rem;
  width: 17.5rem;
  border-radius: 5rem;
  background-color: #242424;
`

const RoadmapBox = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const RoadmapTitle = styled.h3`
  font-size: 2.25rem;
  font-weight: bold;
  text-align: center;
`

const RoadmapContent = styled.div`
  margin-top: 2rem;
  padding: 2rem;
  border-radius: 2rem;
  background-color: ${({ theme }) => useNormal(theme).backgroundSecondary};
`

const RoadmapRow = styled.div`
  display: flex;
  gap: 0.5rem;
  font-size: 1.75rem;
  font-weight: bold;

  & + & {
    margin-top: 1.5rem;
  }
`

const RoadmapPoint = styled.div`
  background: linear-gradient(180deg, #f7cc74 0%, #dbb15a 100%);
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.75rem;
`

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  height: 11rem;
`

const FooterCopyright = styled.p`
  font-size: 0.875rem;
`

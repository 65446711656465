import { FC } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import {
  useCumulativeGains,
  useGains,
  useLastCumulativeGain,
  useMultiFetcher,
} from "../../hooks"
import { useDateRangePlan } from "../../hooks/useDateRangePlan"
import { firebaseAuth } from "../../services"
import {
  CumulativeGainSummary,
  CumulativeGainSummaryLoading,
} from "../organisms"

export const CumulativeGainSummaryContainer: FC = () => {
  const [user] = useAuthState(firebaseAuth)
  const {
    data: { date, range },
  } = useDateRangePlan()
  const { data, isLoading, isIdle, isError } = useMultiFetcher({
    cumulatives: useCumulativeGains(user, date),
    gains: useGains(user, date),
    last: useLastCumulativeGain(user),
    before: useLastCumulativeGain(user, date),
  })
  return isIdle ? (
    <CumulativeGainSummaryLoading />
  ) : isLoading ? (
    <CumulativeGainSummaryLoading />
  ) : isError ? (
    <CumulativeGainSummaryLoading />
  ) : (
    <CumulativeGainSummary
      cumulatives={data.cumulatives}
      gains={data.gains}
      last={data.last!}
      before={data.before}
      range={range}
    />
  )
}

import { User } from "@firebase/auth"
import { collection, getDocs } from "firebase/firestore"
import { useQuery } from "react-query"
import { UseQueryResult } from "react-query/types/react/types"
import { toast } from "react-toastify"
import { AppModel } from "../../types/model"
import { firebaseFirestore } from "../services"

export const useApps = (
  user: User | undefined | null
): UseQueryResult<AppModel[]> =>
  useQuery(
    "Apps",
    () =>
      getDocs(collection(firebaseFirestore, "users", user?.uid!, "apps"))
        .then((data) => {
          const models: AppModel[] = []
          data.forEach((datum) =>
            models.push({
              id: datum.id,
              ...datum.data(),
            } as AppModel)
          )
          return models
        })
        .catch((e) => {
          console.error(e)
          toast.error(e)
          throw e
        }),
    { enabled: !!user?.uid }
  )

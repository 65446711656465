import formatInTimeZone from "date-fns-tz/formatInTimeZone"
import { FC } from "react"
import { GainModel } from "../../../../types/model"
import { useFiatFormat, useI18N } from "../../../hooks"
import { WideTable } from "../../molecules"

export type GainTableProps = {
  gains: GainModel[]
}

export const GainTable: FC<GainTableProps> = ({ gains }) => {
  const { format: fiatFormat } = useFiatFormat()
  const { t } = useI18N()
  return (
    <WideTable
      columns={[
        {
          Header: t("Property", "Date"),
          accessor: "date",
          headerAlign: "center",
          align: "center",
        },
        {
          Header: t("Property", "Status"),
          accessor: "accurate",
          headerAlign: "center",
          align: "center",
        },
        {
          Header: t("Finance", "Gas"),
          accessor: "gas",
          headerAlign: "center",
          align: "right",
        },
        {
          Header: t("Finance", "Income"),
          accessor: "income",
          headerAlign: "center",
          align: "right",
        },
        {
          Header: t("Finance", "Expenses"),
          accessor: "expenses",
          headerAlign: "center",
          align: "right",
        },
        {
          Header: t("Finance", "Net"),
          accessor: "net",
          headerAlign: "center",
          align: "right",
        },
      ]}
      data={gains.map(({ accurate, gas, income, expenses, net, date }) => ({
        date: formatInTimeZone(date.toDate(), "UTC", "MM/dd"),
        accurate: accurate
          ? t("CalculateResult", "Normal")
          : t("CalculateResult", "Defect"),
        gas: fiatFormat(gas),
        income: fiatFormat(income),
        expenses: fiatFormat(expenses),
        net: fiatFormat(net),
      }))}
    />
  )
}

import styled from "@emotion/styled"
import { FC, useState } from "react"
import { Select } from "react-actors/inputs"
import {
  AppModel,
  CoinModel,
  NetworkModel,
  WalletModel,
} from "../../../../types/model"
import { AppFormTable } from "../../organisms"

export type AppCreateTemplateProps = {
  networks?: NetworkModel[]
  coins?: CoinModel[]
  presets?: AppModel[]
  wallets?: WalletModel[]
  onCreate: (value: AppModel) => Promise<void>
}

export const AppCreateTemplate: FC<AppCreateTemplateProps> = ({
  networks,
  coins,
  presets,
  wallets,
  onCreate,
}) => {
  const [preset, setPreset] = useState<AppModel | "INIT">()

  return (
    <>
      <SideContent>
        {presets && (
          <PresetSelect
            defaultValue="INIT"
            onChange={(e) => {
              const value = e.target.value
              if (value === "INIT") return setPreset(value)
              const preset = presets?.find((p) => p.id === value)
              if (!preset) return
              setPreset(preset)
            }}
          >
            <option value="INIT">Preset</option>
            {presets?.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </PresetSelect>
        )}
      </SideContent>
      <AppFormTable
        coins={coins}
        wallets={wallets}
        networks={networks}
        value={preset}
        onCreate={onCreate}
      />
    </>
  )
}

const SideContent = styled.div`
  display: flex;
  justify-content: flex-end;
`

const PresetSelect = styled(Select)`
  width: 10rem;
  margin-bottom: 0.5rem;
`

import { FC, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Signs } from "../../routes/signs"
import { NotFoundTemplate } from "../templates"

export const NotFoundPage: FC = () => {
  const navigate = useNavigate()
  useEffect(() => {
    setTimeout(() => navigate(Signs.Index), 5000)
  })
  return <NotFoundTemplate />
}

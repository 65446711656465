import { User } from "@firebase/auth"
import { subMonths } from "date-fns"
import { where } from "firebase/firestore"
import { CumulativeGainModel } from "../../types/model"
import { useCollection } from "./useCollection"

export const useCumulativeGains = (
  user: User | undefined | null,
  date: Date = subMonths(new Date(), 1)
) =>
  useCollection<CumulativeGainModel>(
    ["CumulativeGains", date.toDateString()],
    ["users", user?.uid, "cumulative_gains"],
    [where("date", ">=", date)]
  )

import styled from "@emotion/styled"
import { User } from "@firebase/auth"
import { FC, ReactNode, useState } from "react"
import { useNormal } from "../../../hooks"
import { ClickDetector } from "../../atoms"
import { Header, HeaderStyles, SideMenu, SideMenuStyles } from "../../organisms"

export type LayoutProps = {
  user?: User
  onLogin?: () => Promise<unknown>
  children?: ReactNode
}

export const Layout: FC<LayoutProps> = ({ user, onLogin, children }) => {
  const [open, setOpen] = useState(false)
  return (
    <Main>
      <Header
        user={user}
        onClickMenu={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setOpen(!open)
        }}
        onLogin={onLogin}
      />
      <ContentBox>
        {open && <ClickDetector onClick={() => setOpen(false)} />}
        <Menu open={open}>
          <SideMenu />
        </Menu>
        <Content>{children}</Content>
      </ContentBox>
    </Main>
  )
}

const Main = styled.main`
  color: ${({ theme }) => useNormal(theme).font};
  background-color: ${({ theme }) => useNormal(theme).background};
`

const ContentBox = styled.div`
  display: grid;
  grid-template-columns: ${SideMenuStyles.width} 1fr;
  min-height: calc(100vh - ${HeaderStyles.height});
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`

const Menu = styled.div<{ open: boolean }>`
  @media (max-width: 800px) {
    display: ${({ open }) => (open ? "block" : "none")};
    position: absolute;
    z-index: 1000;
  }
`

const Content = styled.div`
  padding: 0.75rem 1rem;
`

import styled from "@emotion/styled"
import { FC, useEffect } from "react"
import { Input, Select } from "react-actors/inputs"
import {
  FieldErrors,
  useFieldArray,
  useForm,
  UseFormRegister,
} from "react-hook-form"
import { UseFieldArrayReturn } from "react-hook-form/dist/types"
import {
  AppModel,
  CoinModel,
  NetworkModel,
  WalletModel,
} from "../../../../types/model"
import { TransForm } from "../../../../types/types"
import { I18NReturn, useI18N } from "../../../hooks"
import { transForm } from "../../../utils/transformers/transForm"
import { transModel } from "../../../utils/transformers/transModel"
import { MinusButton, PlusButton } from "../../atoms"
import { TableForm } from "../../molecules"

export type AppFormProps =
  | {
      app?: AppModel
      networks?: NetworkModel[]
      coins?: CoinModel[]
      wallets?: WalletModel[]
      value?: AppModel | "INIT"
      onCreate?: (value: AppModel) => Promise<void>
      onEdit: (value: AppModel) => Promise<void>
    }
  | {
      app?: AppModel
      networks?: NetworkModel[]
      coins?: CoinModel[]
      wallets?: WalletModel[]
      value?: AppModel | "INIT"
      onCreate: (value: AppModel) => Promise<void>
      onEdit?: (value: AppModel) => Promise<void>
    }

type Form = TransForm<AppModel>
export const AppFormTable: FC<AppFormProps> = ({
  app,
  networks,
  coins,
  wallets,
  value,
  onCreate,
  onEdit,
}) => {
  const { t } = useI18N()
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<Form>({
    mode: "onBlur",
    defaultValues: app
      ? Object.entries(transForm(app))
          .filter(([key]) => key !== "id")
          .reduce((pre, [key, value]) => ({ ...pre, [key]: value }), {})
      : {
          coinIds: [{}],
          walletIds: wallets?.length
            ? [
                {
                  _type: "literal",
                  value: wallets[0].address,
                },
              ]
            : [{}],
          addresses: { payin: [{}], payout: [{}] },
        },
  })
  const fields = {
    coins: useFieldArray({ control, name: "coinIds" }),
    wallets: useFieldArray({ control, name: "walletIds" }),
    payin: useFieldArray({ control, name: "addresses.payin" }),
    payout: useFieldArray({ control, name: "addresses.payout" }),
  }

  useEffect(() => {
    if (value === undefined) return
    reset()
    if (value === "INIT") return
    Object.entries(transForm(value))
      .filter(([key]) => key !== "id")
      .forEach(([key, value]) => {
        if (key === "coinIds") {
          ;(value as Form["coinIds"]).forEach((v, i) =>
            i === 0 ? fields.coins.update(i, v) : fields.coins.append(v)
          )
        } else if (key === "walletIds") {
          ;(value as Form["walletIds"]).forEach((v, i) =>
            i === 0 ? fields.wallets.update(i, v) : fields.wallets.append(v)
          )
        } else if (key === "addresses") {
          ;(value as Form["addresses"]).payin.forEach((v, i) =>
            i === 0 ? fields.payin.update(i, v) : fields.payin.append(v)
          )
          ;(value as Form["addresses"]).payout.forEach((v, i) =>
            i === 0 ? fields.payout.update(i, v) : fields.payout.append(v)
          )
        } else {
          setValue(key as keyof AppModel, value)
        }
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <TableForm
      isSubmitting={isSubmitting}
      onCreate={
        onCreate
          ? handleSubmit((form) => onCreate(transModel(form)))
          : undefined
      }
      onEdit={
        onEdit ? handleSubmit((form) => onEdit(transModel(form))) : undefined
      }
      forms={[
        {
          key: "Name",
          label: t("Model", "AppName"),
          form: <Input {...register("name", { required: true })} />,
        },
        {
          key: "Network",
          label: t("Model", "Network"),
          form: (
            <Select
              defaultValue="INIT"
              error={!!errors.networkId}
              {...register("networkId", {
                required: true,
                validate: (v) => v !== "INIT",
              })}
              placeholder="Please select"
            >
              <option value="INIT" disabled style={{ display: "none" }}>
                Please select
              </option>
              {networks?.map((network) => (
                <option key={network.id} value={network.id}>
                  {network.name}
                </option>
              ))}
            </Select>
          ),
        },
        ...CoinForms(coins, t, register, errors, fields.coins),
        ...WalletForm(wallets, t, register, errors, fields.wallets),
        ...PayinForm(t, register, errors, fields.payin),
        ...PayoutForm(t, register, errors, fields.payout),
      ]}
    />
  )
}

const CoinForms = (
  coins: CoinModel[] | undefined,
  t: I18NReturn["t"],
  register: UseFormRegister<Form>,
  errors: FieldErrors<Form>,
  form: UseFieldArrayReturn<Form, "coinIds">
) =>
  form.fields.map((field, index) => ({
    key: field.id,
    label: index === 0 ? t("Model", "Coin") : "",
    form: (
      <ArrayForm>
        <input
          type="hidden"
          {...register(`coinIds.${index}._type`)}
          value="literal"
        />
        <Select
          defaultValue="INIT"
          error={!!errors.coinIds?.[index]?.value}
          {...register(`coinIds.${index}.value`, {
            required: true,
            validate: (v) => v !== "INIT",
          })}
        >
          <option value="INIT" disabled style={{ display: "none" }}>
            Please select
          </option>
          {coins?.map((coin) => (
            <option key={coin.id} value={coin.id}>
              {coin.name}
            </option>
          ))}
        </Select>
        {index === 0 ? (
          <PlusButton onClick={() => form.append({})} />
        ) : (
          <MinusButton onClick={() => form.remove(index)} />
        )}
      </ArrayForm>
    ),
  }))

const WalletForm = (
  wallets: WalletModel[] | undefined,
  t: I18NReturn["t"],
  register: UseFormRegister<Form>,
  errors: FieldErrors<Form>,
  form: UseFieldArrayReturn<Form, "walletIds">
) =>
  form.fields.map((field, index) => ({
    key: field.id,
    label: index === 0 ? t("Menu", "Wallet") : "",
    form: (
      <ArrayForm>
        <input
          type="hidden"
          {...register(`walletIds.${index}._type`)}
          value="literal"
        />
        <Input
          type="text"
          autoComplete="on"
          list="wallets"
          error={!!errors.walletIds?.[index]?.value}
          {...register(`walletIds.${index}.value`, {
            required: true,
          })}
        />
        <datalist id="wallets">
          {wallets?.map((wallet) => (
            <option key={wallet.id} value={wallet.id}>
              {wallet.label}({wallet.address})
            </option>
          ))}
        </datalist>
        {index === 0 ? (
          <PlusButton onClick={() => form.append({})} />
        ) : (
          <MinusButton onClick={() => form.remove(index)} />
        )}
      </ArrayForm>
    ),
  }))

const PayoutForm = (
  t: I18NReturn["t"],
  register: UseFormRegister<Form>,
  errors: FieldErrors<Form>,
  form: UseFieldArrayReturn<Form, "addresses.payout">
) =>
  form.fields.map((field, index) => ({
    key: field.id,
    label: index === 0 ? t("Model", "AddressPayOut") : "",
    form: (
      <ArrayForm>
        <input
          type="hidden"
          {...register(`addresses.payout.${index}._type`)}
          value="literal"
        />
        <Input
          error={!!errors.addresses?.payin?.[index]?.value}
          {...register(`addresses.payout.${index}.value`, { required: true })}
        />
        {index === 0 ? (
          <PlusButton onClick={() => form.append({})} />
        ) : (
          <MinusButton onClick={() => form.remove(index)} />
        )}
      </ArrayForm>
    ),
  }))

const PayinForm = (
  t: I18NReturn["t"],
  register: UseFormRegister<Form>,
  errors: FieldErrors<Form>,
  form: UseFieldArrayReturn<Form, "addresses.payin">
) =>
  form.fields.map((field, index) => ({
    key: field.id,
    label: index === 0 ? t("Model", "AddressPayIn") : "",
    form: (
      <ArrayForm>
        <input
          type="hidden"
          {...register(`addresses.payin.${index}._type`)}
          value="literal"
        />
        <Input
          error={!!errors.addresses?.payin?.[index]?.value}
          {...register(`addresses.payin.${index}.value`, { required: true })}
        />
        {index === 0 ? (
          <PlusButton onClick={() => form.append({})} />
        ) : (
          <MinusButton onClick={() => form.remove(index)} />
        )}
      </ArrayForm>
    ),
  }))

const ArrayForm = styled.div`
  display: flex;
  gap: 0.5rem;
`

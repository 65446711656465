import { FC } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { useParams } from "react-router-dom"
import { useCoins, useMultiFetcher, useTransactions } from "../../hooks"
import { firebaseAuth } from "../../services"
import { TransactionTable } from "../organisms"

export const TransactionTableContainer: FC = () => {
  const { id } = useParams<{ id: string }>()
  const [user] = useAuthState(firebaseAuth)
  const { data, isLoading, isError } = useMultiFetcher({
    transactions: useTransactions(user, id),
    coins: useCoins(),
  })
  return isLoading || isError ? (
    <>Loading</>
  ) : (
    <TransactionTable transactions={data.transactions} coins={data.coins} />
  )
}

import { FC } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { useParams } from "react-router-dom"
import { useApp, useCoins, useMultiFetcher, usePrices } from "../../hooks"
import { firebaseAuth } from "../../services"
import { PriceTable, PriceTableLoading } from "../organisms"

export const PriceTableContainer: FC = () => {
  const { id, coinId } = useParams<{ id: string; coinId?: string }>()
  const [user] = useAuthState(firebaseAuth)
  const { data, isLoading, isIdle, isError } = useMultiFetcher({
    app: useApp(user, id),
    prices: usePrices({}),
    coins: useCoins(),
  })
  return isIdle ? (
    <PriceTableLoading />
  ) : isLoading ? (
    <PriceTableLoading />
  ) : isError ? (
    <PriceTableLoading />
  ) : (
    <PriceTable
      coinId={coinId ?? data.app.coinIds[0]}
      coins={data.coins}
      prices={data.prices}
    />
  )
}

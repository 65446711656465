import styled from "@emotion/styled"
import { FC } from "react"
import { Button as _Button } from "react-actors/buttons"
import { useI18N } from "../../../hooks"

export type HeaderLoginProps = {
  onClick?: () => Promise<unknown>
}

export const HeaderLogin: FC<HeaderLoginProps> = ({ onClick }) => {
  const { t } = useI18N()
  return (
    <Box>
      <Button color="normal" onClick={onClick}>
        {t("Auth", "SignIn")}
      </Button>
      <Button color="secondary" onClick={onClick}>
        {t("Auth", "Register")}
      </Button>
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  gap: 0.5rem;
`

const Button = styled(_Button)`
  padding: 1rem 1.5rem;
  border-radius: 5rem;
`

import styled from "@emotion/styled"
import { FC } from "react"
import { Button } from "react-actors/buttons"
import { useNavigate } from "react-router-dom"
import { AppModel, CumulativeGainModel } from "../../../../types/model"
import { useFiatFormat, useI18N } from "../../../hooks"
import { Signs } from "../../../routes/signs"
import { Currency } from "../../atoms/Currency"
import { Content, Media, WideTable } from "../../molecules"

export type AppListTemplateProps = {
  apps: AppModel[]
  aggregates: CumulativeGainModel[]
}

export const AppListTemplate: FC<AppListTemplateProps> = ({
  apps,
  aggregates,
}) => {
  const navigate = useNavigate()
  const { t } = useI18N()
  const { format } = useFiatFormat()
  return (
    <Content
      title={t("Menu", "Application")}
      side={
        <ButtonGroup>
          <Button color="secondary" onClick={() => navigate(Signs.AppCreate)}>
            {t("Actions", "Edit")}
          </Button>
          <Button color="secondary" onClick={() => navigate(Signs.AppCreate)}>
            {t("Actions", "Create")}
          </Button>
        </ButtonGroup>
      }
    >
      <Media>
        <WideTable
          columns={[
            { Header: t("Model", "App"), accessor: "name" },
            {
              Header: t("Finance", "Net"),
              accessor: "net",
              Wrapper: ({ children }) => (
                <div style={{ textAlign: "right" }}>
                  <Currency>{children}</Currency>
                </div>
              ),
            },
          ]}
          data={
            apps?.map(({ id, name }) => ({
              id,
              name,
              net: format(
                aggregates?.find((aggregate) => aggregate.id === id)?.net ?? 0
              ),
            })) ?? []
          }
          onClick={({ id }) => navigate(`/apps/${id}`)}
        />
      </Media>
    </Content>
  )
}

const ButtonGroup = styled.div`
  display: flex;
  gap: 0.5rem;
`

import { IconMinus } from "@tabler/icons"
import { FC } from "react"
import { Button } from "react-actors/buttons/index"

export type MinusButtonProps = {
  onClick: () => unknown
}

export const MinusButton: FC<MinusButtonProps> = ({ onClick }) => (
  <Button size="small" color="danger" onClick={onClick}>
    <IconMinus />
  </Button>
)

import { QueryConstraint } from "@firebase/firestore"
import { collection, getDocs, query } from "firebase/firestore"
import { QueryKey, useQuery } from "react-query"
import { UseQueryResult } from "react-query/types/react/types"
import { toast } from "react-toastify"
import { firebaseFirestore } from "../services"

export const useCollection = <T extends { id: string }>(
  key: QueryKey,
  paths: [string, ...(string | undefined)[]],
  conditions: QueryConstraint[] = [],
  enabled: boolean = true
): UseQueryResult<T[]> =>
  useQuery(
    key,
    () =>
      getDocs(
        query(
          collection(firebaseFirestore, ...(paths as [string, ...string[]])),
          ...conditions
        )
      )
        .then((ref) => {
          const models: T[] = []
          ref.forEach((datum) =>
            models.push({
              id: datum.id,
              ...datum.data(),
            } as T)
          )
          return models
        })
        .catch((e) => {
          console.error(e)
          toast.error(e)
          throw e
        }),
    { enabled: paths.every((path) => !!path) && enabled }
  )

import styled from "@emotion/styled"
import format from "date-fns/format"
import { FC, ReactNode } from "react"

export type ContentHeaderProps = {
  title: string
  range?: Date
  side?: ReactNode
}

export const ContentHeader: FC<ContentHeaderProps> = ({
  title,
  range,
  side,
}) => (
  <Box>
    <Main>
      <Title>{title}</Title>
      {range && (
        <Range>
          ({format(range, "MM/dd")}〜{format(new Date(), "MM/dd")})
        </Range>
      )}
    </Main>
    {side}
  </Box>
)

const Box = styled.div`
  display: flex;
  justify-content: space-between;
`

const Main = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`

const Range = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 1rem;
`
const Title = styled.h2`
  font-size: 2rem;
  font-weight: bolder;
`

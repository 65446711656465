import { httpsCallable } from "firebase/functions"
import { FC } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import {
  useApp,
  useCoins,
  useMultiFetcher,
  useNetworks,
  useWallets,
} from "../../hooks"
import { firebaseAuth, firebaseFunctions } from "../../services"
import { LoadingTemplate } from "../templates"
import { AppEditTemplate } from "../templates/AppEditTemplate/AppEditTemplate"

export const AppEditPage: FC = () => {
  const push = useNavigate()
  const { id } = useParams<{ id: string }>()
  const [user] = useAuthState(firebaseAuth)
  const { data, isLoading, isError } = useMultiFetcher({
    app: useApp(user, id),
    coins: useCoins(),
    networks: useNetworks(),
    wallets: useWallets(user),
  })
  return isLoading || isError ? (
    <LoadingTemplate />
  ) : (
    <AppEditTemplate
      app={data.app}
      networks={data.networks}
      coins={data.coins}
      wallets={data.wallets}
      onEdit={(form) =>
        httpsCallable(
          firebaseFunctions,
          "updateApp"
        )({ appId: id, form })
          .then(() => push(`/apps/${id}`))
          .catch((e) => {
            console.error(e)
            toast.error(e)
            throw e
          })
      }
      onDelete={() =>
        httpsCallable(
          firebaseFunctions,
          "deleteApp"
        )({ appId: id })
          .then(() => push("/apps"))
          .catch((e) => {
            console.error(e)
            toast.error(e)
            throw e
          })
      }
    />
  )
}

import { IconPlus } from "@tabler/icons"
import { FC } from "react"
import { Button } from "react-actors/buttons"

export type PlusButtonProps = {
  onClick: () => unknown
}

export const PlusButton: FC<PlusButtonProps> = ({ onClick }) => (
  <Button size="small" onClick={onClick}>
    <IconPlus />
  </Button>
)

import { subMonths } from "date-fns"
import { useCurrentPlan } from "./useCurrentPlan"

export const useDateRangePlan = () => {
  const { data, ...result } = useCurrentPlan()
  const getRange = (product: "King" | "Load") => {
    if (product === "King") {
      return Number.POSITIVE_INFINITY
    } else if (product === "Load") {
      return 3
    }
    return 1
  }
  const range =
    data && !result.isLoading && !result.isIdle
      ? getRange(data.name as "King" | "Load")
      : 1
  return {
    ...result,
    data: {
      range,
      date:
        range === Number.POSITIVE_INFINITY
          ? new Date(0)
          : subMonths(new Date(), range),
    },
  }
}

import { getProducts } from "@stripe/firestore-stripe-payments"
import { Product } from "@stripe/firestore-stripe-payments/lib/product"
import { useQuery } from "react-query"
import { payments } from "../services/stripe"

export const useProducts = () =>
  useQuery<Product[]>("Products", () =>
    getProducts(payments, {
      includePrices: true,
      activeOnly: true,
    }).then((data) =>
      data.sort(
        (a, b) =>
          Number(a.metadata?.Order ?? 0) - Number(b.metadata?.Order ?? 0)
      )
    )
  )

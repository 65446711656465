import styled from "@emotion/styled"
import { Product } from "@stripe/firestore-stripe-payments/lib/product"
import { Subscription } from "@stripe/firestore-stripe-payments/lib/subscription"
import { FC } from "react"
import { useI18N } from "../../../hooks"
import { Content } from "../../molecules"
import { PlanTemplateCard } from "./PlanTemplateCard"

export type PlanTemplateProps = {
  plans: Product[]
  subscription?: Subscription
  onPortal: () => Promise<void>
  onSubscribe: (id: string) => Promise<void>
}

const InfoMap = {
  Load: ["ThreeMonth", "UpdateAll"],
  King: ["Infinity", "UpdateAll"],
} as const

export const PlanTemplate: FC<PlanTemplateProps> = ({
  subscription,
  plans,
  onPortal,
  onSubscribe,
}) => {
  const { t } = useI18N()
  return (
    <Content title={t("Menu", "Plan")}>
      <p>{t("Plan", "Detail")}</p>
      <CardBox>
        <PlanTemplateCard
          title="Basic"
          value={0}
          infos={[t("Plan", "OneMonth")]}
          current={!subscription}
          onClick={onPortal}
        />
        {plans.map(({ id, name, prices }) => (
          <PlanTemplateCard
            key={id}
            title={name as "Load" | "King"}
            value={prices[0].unit_amount ?? 0}
            current={subscription?.product === id}
            infos={InfoMap[name as "Load" | "King"].map((value) =>
              t("Plan", value)
            )}
            onClick={() => onSubscribe(prices[0].id)}
          />
        ))}
      </CardBox>
    </Content>
  )
}

const CardBox = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`

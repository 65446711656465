import { FC } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { useWallets } from "../../hooks"
import { firebaseAuth } from "../../services"
import { LoadingTemplate, WalletListTemplate } from "../templates"

export const WalletListPage: FC = () => {
  const [users] = useAuthState(firebaseAuth)
  const { data, isError, isLoading } = useWallets(users)
  return isLoading || isError || !data ? (
    <LoadingTemplate />
  ) : (
    <WalletListTemplate data={data} />
  )
}

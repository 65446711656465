import { LiteralObject, TransForm } from "../../../types/types"

export const transModel = <T extends TransForm<M>, M extends object>(
  model: T
): M =>
  Object.entries(model).reduce(
    (pre, [key, value]) => ({
      ...pre,
      [key]: isObject(value) ? transModel(value) : convert(value),
    }),
    {} as M
  )

const isObject = (value: any): value is object =>
  value !== null && typeof value === "object" && !Array.isArray(value)

const convert = <T>(values: T): T | LiteralObject[] =>
  Array.isArray(values) && values.every(isLiteralObject)
    ? values.map(({ value }) => value)
    : values

const isLiteralObject = (object: any): object is LiteralObject =>
  Object.hasOwn(object, "_type") && object._type === "literal"

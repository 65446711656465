import styled from "@emotion/styled"
import { FC, ReactNode } from "react"

export type OptionalTextProps = {
  children: ReactNode
}

export const OptionalText: FC<OptionalTextProps> = ({ children }) => (
  <Box>{children}</Box>
)

const Box = styled.div`
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

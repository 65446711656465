import { Product } from "@stripe/firestore-stripe-payments/lib/product"
import { FC } from "react"
import { Button } from "react-actors/buttons"

export type SubscribeTemplateProps = {
  product: Product
  onSubscribe: () => void
}

export const SubscribeTemplate: FC<SubscribeTemplateProps> = ({
  product,
  onSubscribe,
}) => (
  <div>
    {product.name}
    <Button color="secondary" onClick={onSubscribe}>
      Subscribe
    </Button>
  </div>
)

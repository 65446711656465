import { User } from "@firebase/auth/dist/node-esm"
import { doc, getDoc } from "firebase/firestore"
import { useQuery } from "react-query"
import { UseQueryResult } from "react-query/types/react/types"
import { toast } from "react-toastify"
import { WalletModel } from "../../types/model"
import { firebaseFirestore } from "../services"

export const useWallet = (
  user: User | undefined | null,
  address: string
): UseQueryResult<WalletModel> =>
  useQuery(
    ["Wallets", address],
    () =>
      getDoc(doc(firebaseFirestore, "users", user?.uid!, "wallets", address))
        .then((data) => data.data())
        .catch((e) => {
          console.error(e)
          toast.error(e)
          throw e
        }),
    { enabled: !!user?.uid }
  )

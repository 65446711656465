import { createCheckoutSession } from "@stripe/firestore-stripe-payments"
import { FC } from "react"
import { useParams } from "react-router-dom"
import { useProduct } from "../../hooks"
import { payments } from "../../services/stripe"
import { SubscribeTemplate } from "../templates"

export const SubscribePage: FC = () => {
  const { id } = useParams()
  const { data } = useProduct(id)
  return data ? (
    <SubscribeTemplate
      product={data}
      onSubscribe={async () => {
        const session = await createCheckoutSession(payments, {
          price: data.prices[0].id,
        })
        window.location.assign(session.url)
      }}
    />
  ) : null
}

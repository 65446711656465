export const Signs = {
  Index: "/",
  Setup: "/setup",
  Mypage: "/mypage",
  AppList: "/apps",
  AppCreate: "/apps/new",
  AppDetail: "/apps/:id",
  AppEdit: "/apps/:id/edit",
  AppDetailGains: "/apps/:id/gains",
  AppDetailPrices: "/apps/:id/prices",
  WalletList: "/wallets",
  WalletCreate: "/wallets/new",
  WalletEdit: "/wallets/:address",
  Plan: "/plans",
  Subscribe: "/plans/:id/subscribe",
  Setting: "/setting",
}

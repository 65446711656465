import { FC } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { useParams } from "react-router-dom"
import { useAppGains } from "../../hooks"
import { firebaseAuth } from "../../services"
import { GainTable, GainTableLoading } from "../organisms/GainTable"

export const GainTableContainer: FC = () => {
  const { id } = useParams<{ id: string }>()
  const [user] = useAuthState(firebaseAuth)
  const { data, isLoading, isIdle, isError } = useAppGains(user, id)
  return isIdle ? (
    <GainTableLoading />
  ) : isLoading ? (
    <GainTableLoading />
  ) : isError ? (
    <GainTableLoading />
  ) : (
    <GainTable gains={data} />
  )
}

import { FC, ReactNode } from "react"
import {
  INITIAL_THEME,
  ThemeProvider as ActorsThemeProvider,
} from "react-actors/providers"
import { QueryClient, QueryClientProvider } from "react-query"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { RecoilRoot } from "recoil"
import { usePersistentMode } from "./hooks"
import { Roads } from "./routes/Roads"

export const queryClient = new QueryClient()

export const App = () => (
  <RecoilRoot>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <Roads />
        <ToastContainer position="bottom-right" />
      </ThemeProvider>
    </QueryClientProvider>
  </RecoilRoot>
)
export const NFTKingTheme = {
  ...INITIAL_THEME,
  primary: {
    dark: {
      font: "#fff",
      border: "#E8871C",
      background: "#E8871C",
    },
    light: {
      font: "#fff",
      border: "#E8871C",
      background: "#E8871C",
    },
  },
  success: {
    dark: {
      font: "#fff",
      border: "#6DE99E",
      background: "#6DE99E",
    },
    light: {
      font: "#fff",
      border: "#6DE99E",
      background: "#6DE99E",
    },
  },
  danger: {
    dark: {
      font: "#fff",
      border: "#D85473",
      background: "#D85473",
    },
    light: {
      font: "#fff",
      border: "#D85473",
      background: "#D85473",
    },
  },
  normal: {
    dark: {
      background: "var(--zinc-600)",
      backgroundSecondary: "var(--zinc-700)",
      backgroundImportant: "var(--zinc-800)",
      font: "white",
      border: "",
    },
    light: {
      background: "#FAFAFA",
      backgroundSecondary: "#fff",
      backgroundImportant: "var(--gray-200)",
      font: "black",
      border: "",
    },
  },
}

const ThemeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [mode] = usePersistentMode()
  return (
    <ActorsThemeProvider theme={NFTKingTheme} mode={mode}>
      {children}
    </ActorsThemeProvider>
  )
}

import styled from "@emotion/styled"
import { FC, ReactNode } from "react"
import { useNormal } from "../../../hooks"
import { Currency } from "../../atoms/Currency"

type Data = {
  key: string
  head: ReactNode
  content: ReactNode
}

export type DataSummaryProps = {
  contents: Data[]
  className?: string
}

export const DataSummary: FC<DataSummaryProps> = ({ contents, className }) => (
  <Box className={className}>
    {contents.map(({ key, head, content }) => (
      <Row key={key}>
        <Headline>{head}</Headline>
        <Content>{content}</Content>
      </Row>
    ))}
  </Box>
)

const Row = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  padding: 0.25rem;

  &:nth-child(odd) {
    background-color: ${({ theme }) => useNormal(theme).background};
  }

  & + & {
    margin-top: 0.25rem;
  }
`

const Box = styled.div`
  width: 100%;
`

const Headline = styled.div`
  font-weight: bolder;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Content = styled(Currency)`
  width: 8rem;
  text-align: right;
  word-break: keep-all;
`

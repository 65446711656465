import styled from "@emotion/styled"
import { User } from "@firebase/auth"
import { FC, ReactNode } from "react"
import { useNormal } from "../../../hooks"
import { Header } from "../../organisms"

export type ExtendedLayoutProps = {
  user?: User
  onLogin?: () => Promise<unknown>
  children?: ReactNode
}

export const ExtendedLayout: FC<ExtendedLayoutProps> = ({
  user,
  onLogin,
  children,
}) => (
  <Main>
    <Header user={user} onLogin={onLogin} />
    <Content>{children}</Content>
  </Main>
)

const Main = styled.main`
  color: ${({ theme }) => useNormal(theme).font};
  background-color: ${({ theme }) => useNormal(theme).background};
`

const Content = styled.div`
  display: flex;
  justify-content: center;
`

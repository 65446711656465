import { getProduct } from "@stripe/firestore-stripe-payments"
import { Product } from "@stripe/firestore-stripe-payments/lib/product"
import { useQuery } from "react-query"
import { payments } from "../services/stripe"

export const useProduct = (id?: string) =>
  useQuery<Product>(
    ["Product", id],
    () =>
      getProduct(payments, id!, {
        includePrices: true,
      }),
    { enabled: !!id }
  )

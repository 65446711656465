import styled from "@emotion/styled"
import { FC } from "react"
import { Button } from "react-actors/buttons"
import { Input } from "react-actors/inputs"
import { useForm } from "react-hook-form"
import { WalletModel } from "../../../../types/model"
import { useI18N } from "../../../hooks/useI18N"

export type WalletEditTemplateProps = {
  onUpdate: (wallet: WalletModel) => Promise<unknown>
  onDelete: (wallet: WalletModel) => Promise<unknown>
  data: WalletModel
}

export const WalletEditTemplate: FC<WalletEditTemplateProps> = ({
  onUpdate,
  onDelete,
  data,
}) => {
  const {
    handleSubmit: handleSubmitDelete,
    formState: { isSubmitting: isSubmittingDelete },
  } = useForm<WalletModel>({ mode: "onBlur", defaultValues: data })
  const {
    register,
    handleSubmit,
    formState: { isDirty, errors, isSubmitting },
  } = useForm<WalletModel>({ mode: "onBlur", defaultValues: data })
  const { t } = useI18N()

  return (
    <>
      <Table>
        <tbody>
          <Row>
            <th>{t("Wallet", "Address")}</th>
            <td>
              <Input
                error={!!errors.address}
                {...register("address", {
                  required: true,
                  pattern: {
                    value: /^0x[a-zA-Z\d]{40}$/g,
                    message: "Invalid address pattern",
                  },
                })}
              />
            </td>
          </Row>
          {errors.address && (
            <Row>
              <td colSpan={2}>{errors.address.message}</td>
            </Row>
          )}
          <Row>
            <th>{t("Wallet", "Label")}</th>
            <td>
              <Input error={!!errors.label} {...register("label")} />
            </td>
          </Row>
        </tbody>
      </Table>
      <Container>
        <form onSubmit={handleSubmit(onUpdate)}>
          <Button loading={isSubmitting} disabled={!isDirty} type="submit">
            {t("Actions", "Update")}
          </Button>
        </form>
        <Spacer />
        <form onSubmit={handleSubmitDelete(onDelete)}>
          <Button loading={isSubmittingDelete} color="danger" type="submit">
            {t("Actions", "Delete")}
          </Button>
        </form>
      </Container>
    </>
  )
}

const Spacer = styled.div`
  width: 1rem;
`

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 20px;
`

const Table = styled.table`
  width: 100%;
`

const Row = styled.tr`
  & + & {
    padding-top: 0.5rem;
  }
`

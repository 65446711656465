import { doc, getDoc } from "firebase/firestore"
import { QueryKey, useQuery } from "react-query"
import { UseQueryResult } from "react-query/types/react/types"
import { toast } from "react-toastify"
import { firebaseFirestore } from "../services"

export const useDoc = <T extends { id: string }>(
  key: QueryKey,
  ...paths: [string, ...(string | undefined)[]]
): UseQueryResult<T> =>
  useQuery(
    key,
    () =>
      getDoc(doc(firebaseFirestore, ...(paths as [string, ...string[]])))
        .then((ref) => {
          if (!ref.exists()) {
            throw new Error(`Not Found Document\n  ${paths.join(".")}`)
          }
          return { id: ref.id, ...ref.data() }
        })
        .catch((e) => {
          console.error(e)
          toast.error(e)
          throw e
        }),
    { enabled: paths.every((path) => !!path) }
  )

import { Language } from "../type"

export const en: Language = {
  Auth: {
    SignIn: "SignIn",
    Logout: "Logout",
    Register: "Register",
  },
  Model: {
    App: "App",
    AppName: "App Name",
    Coin: "Coin",
    Wallet: "Wallet",
    WalletName: "Wallet Name",
    Network: "Network",
    AddressPayIn: "Pay In Address",
    AddressPayOut: "Pay Out Address",
  },
  Menu: {
    Mypage: "Mypage",
    Application: "Application",
    Wallet: "Wallet",
    Plan: "Plan",
    Setting: "Setting",
  },
  Math: {
    Kilo: "K",
  },
  Finance: {
    Gas: "Gas",
    Income: "Income",
    Expenses: "Expenses",
    Net: "Net",
    CumulativeNet: "Cumulative Net",
    CumulativeIncome: "Cumulative Income",
    CumulativeExpenses: "Cumulative Expenses",
    MonthlyNet: "Monthly Net",
    MonthlyIncome: "Monthly Income",
    MonthlyExpenses: "Monthly Expenses",
    BreakdownIncome: "Income Breakdown",
    BreakdownExpenses: "Expenses Breakdown",
    // AverageNet: "Average Gains",
    // RecentNet: "Recent Gains",
    // DaysTillProfit: "Days until profit",
  },
  Actions: {
    Edit: "Edit",
    Create: "Create",
    Update: "Update",
    Delete: "Delete",
    Cancel: "Cancel",
  },
  Wallet: {
    Address: "Address",
    PayOut: "Payout",
    PayIn: "PayIn",
    Label: "Label",
  },
  Setting: {
    Language: "Language",
    Fiat: "Fiat Currency",
    IncomeCalculation: "Income Calculation",
    ExpensesCalculation: "Expenses Calculation",
  },
  Fiat: {
    USD: "USD",
    JPY: "JPY",
  },
  CalculateType: {
    Min: "Minimum price",
    Max: "Maximum price",
    Avg: "Average price",
  },
  Component: {
    TotalNet: "Balance per application",
  },
  CalculateResult: {
    Normal: "Normal",
    Defect: "Defect",
  },
  Plan: {
    Plan: "Plan",
    Detail: "Let's get some longer term stats and get the upper hand!",
    Current: "Current",
    Basic: "Basic",
    Load: "Load",
    King: "King",
    OneMonth: "Income and expenditure information per month",
    ThreeMonth: "Income and expenditure information for 3 months",
    Infinity: "Indefinite income and expenditure information",
    UpdateAll: "Batch transaction fetch button",
    Becoming: "Becoming a",
    BecomingOrder: "After",
  },
  Date: {
    Month: "Month",
  },
  Property: {
    Date: "Date",
    Status: "Status",
    Hash: "Hash",
    Value: "Value",
  },
}

import styled from "@emotion/styled"
import { IconDeviceGamepad, IconHome2, IconWallet } from "@tabler/icons"
import { httpsCallable } from "firebase/functions"
import { FC } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { TransactionRefreshInput } from "../../../../types/model"
import { useApps, useCurrentPlan, useI18N, useNormal } from "../../../hooks"
import { Signs } from "../../../routes/signs"
import { firebaseAuth, firebaseFunctions } from "../../../services"
import { RefreshButton } from "../../atoms"
import { HeaderStyles } from "../Header"

export type SideMenuProps = {
  className?: string
}

export const SideMenu: FC<SideMenuProps> = ({ className }) => {
  const { t } = useI18N()
  const [user] = useAuthState(firebaseAuth)
  const { data } = useApps(user)
  const { data: plan } = useCurrentPlan()
  return (
    <Box className={className}>
      <Navigation>
        <Link to={Signs.Mypage}>
          <LinkBox>
            <IconHome2 />
            {t("Menu", "Mypage")}
          </LinkBox>
        </Link>
        <Link to={Signs.AppList}>
          <ExpandLinkBox>
            <ExpandInline>
              <IconDeviceGamepad />
              {t("Menu", "Application")}
            </ExpandInline>
            <RefreshButton
              disabled={!["King", "Load"].includes(plan?.name ?? "")}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                return httpsCallable(
                  firebaseFunctions,
                  "refreshAllApp"
                )({
                  mode: "refresh",
                }).catch((e) => {
                  console.error(e)
                  toast.error("Refresh Error")
                  throw e
                })
              }}
            />
          </ExpandLinkBox>
        </Link>
        <InlineContent>
          {data?.map((datum) => (
            <Link key={datum.id} to={Signs.AppDetail.replace(":id", datum.id)}>
              <InlineLinkBox>
                <InlineLinkText>{datum.name}</InlineLinkText>
                <RefreshButton
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    return httpsCallable(
                      firebaseFunctions,
                      "refreshApp"
                    )({
                      appId: datum.id,
                      mode: "refresh",
                    } as TransactionRefreshInput).catch((e) => {
                      console.error(e)
                      toast.error("Refresh Error")
                      throw e
                    })
                  }}
                />
              </InlineLinkBox>
            </Link>
          ))}
        </InlineContent>
        <Link to={Signs.WalletList}>
          <LinkBox>
            <IconWallet />
            {t("Menu", "Wallet")}
          </LinkBox>
        </Link>
      </Navigation>
      <Footer>Copyright © {new Date().getFullYear()} Tanmen</Footer>
    </Box>
  )
}

export const SideMenuStyles = {
  width: "15rem",
}

const Box = styled.div`
  position: sticky;
  top: ${HeaderStyles.height};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - ${HeaderStyles.height});
  overflow: hidden;
  background-color: ${({ theme }) => useNormal(theme).backgroundSecondary};
`

const Navigation = styled.nav`
  overflow: hidden;
  height: calc(100% - 3rem);
`

const ExpandLinkBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => useNormal(theme).background};
  }
`

const ExpandInline = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.75rem 1rem;
`

const LinkBox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.75rem 1rem;

  &:hover {
    background-color: ${({ theme }) => useNormal(theme).background};
  }
`

const InlineLinkBox = styled(LinkBox)`
  display: flex;
  justify-content: space-between;
  padding: 0;
`

const InlineLinkText = styled.div`
  padding: 0.75rem 1rem 0.75rem 1.5rem;
`

const InlineContent = styled.div`
  max-height: 70%;
  overflow-y: auto;
  background-color: ${({ theme }) => useNormal(theme).backgroundImportant};
`

const Footer = styled.footer`
  display: flex;
  padding: 0.75rem;
  justify-content: center;
  align-items: center;
  height: 3rem;
`

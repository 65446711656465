import styled from "@emotion/styled"
import { FC, ReactNode } from "react"
import { ContentHeader, ContentHeaderProps } from "../../organisms"

export type ContentProps = ContentHeaderProps & {
  className?: string
  children?: ReactNode
}

export const Content: FC<ContentProps> = ({
  className,
  children,
  ...title
}) => (
  <Box className={className}>
    <ContentHeader {...title} />
    <Main>{children}</Main>
  </Box>
)

const Box = styled.div``

const Main = styled.div`
  margin-top: 1rem;

  & > * + * {
    margin-top: 1.5rem;
  }
`

import { LiteralObject, TransForm } from "../../../types/types"

export const transForm = <T extends {}>(model: T): TransForm<T> =>
  Object.entries(model).reduce(
    (pre, [key, value]) =>
      key !== "id"
        ? {
            ...pre,
            [key]: isObject(value) ? transForm(value) : convert(value),
          }
        : pre,
    {} as TransForm<T>
  )

const isObject = (value: any): value is object =>
  value !== null && typeof value === "object" && !Array.isArray(value)

const convert = <T>(values: T): T | LiteralObject[] =>
  Array.isArray(values) && values.every(isLiteral)
    ? values.map((value) => ({ _type: "literal", id: value, value }))
    : values

const isLiteral = (object: any): object is string => typeof object === "string"

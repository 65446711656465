import { useTranslation } from "react-i18next"
import { DeepestPaths } from "../../types/types"
import { Language } from "../i18n/type"

export type I18NReturn = {
  t: (...keys: DeepestPaths<Language>) => string
  join: (...word: string[]) => string
  builtIn: (first: string, second: string, order?: "After" | "Before") => string
}

export const useI18N = (): I18NReturn => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation()
  const join = (...word: string[]) =>
    resolvedLanguage === "ja" ? word.join("") : word.join(" ")
  return {
    t: (...keys: DeepestPaths<Language>) => t(keys.join(".")),
    join,
    builtIn: (first, second, order) =>
      order !== "After" ? join(first, second) : join(second, first),
  }
}

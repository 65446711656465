import { FC } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import {
  useAggregateCumulativeGains,
  useApps,
  useMultiFetcher,
} from "../../hooks"
import { firebaseAuth } from "../../services"
import { AggregateSummary, AggregateSummaryLoading } from "../organisms"

export const AggregateSummaryContainer: FC = () => {
  const [user] = useAuthState(firebaseAuth)
  const { data, isIdle, isLoading, isError } = useMultiFetcher({
    apps: useApps(user),
    aggregates: useAggregateCumulativeGains(user),
  })
  return isIdle ? (
    <AggregateSummaryLoading />
  ) : isLoading ? (
    <AggregateSummaryLoading />
  ) : isError ? (
    <AggregateSummaryLoading />
  ) : (
    <AggregateSummary apps={data.apps} aggregates={data.aggregates} />
  )
}
